import React, { useRef,useState } from "react";
import Select from "react-select";
import CloseIcon from '@mui/icons-material/Close';
import {STATES_FAC_FACTURA} from "../Componentes/States"
import { DateRange } from 'react-date-range';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {Button} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { COLUMNS_LISTA_COTIZACION,COLUMNS_RE_RECUENTO_DETALLE,COLUMNS_LISTA_FACTURA,COLUMNS_DC_COMPRA_DETALLE,COLUMNS_LISTA_CLIENTE,COLUMNS_LISTA_FPAGO,COLUMNS_LISTA_ITEMS,COLUMNS_COTD_COOTIZACION_DETALLE,COLUMNS_LISTA_ANULACION} from "../Componentes/DatatableColumns";
import { MaterialReactTable} from "material-react-table";
import ImageUploading from "react-images-uploading";
import {Label,Row,Col} from "reactstrap";
import {dataDropdownTipo,dataDropdownPrint} from "./Services";
import {roundNumber,FOOTER_FACTURA} from "./Util";
import {DATATABLE_COLUMNS_LISTA_ORDENES,DATATABLE_COLUMNS_LISTA_CREDITOS_PAGO,DATATABLE_COLUMNS_LISTA_CREDITOS_DETALLE,DATATABLE_COLUMNS_LISTA_CREDITOS,DATATABLE_COLUMNS_RENCUENTO,DATATABLE_COLUMNS_LISTA_COTIZACION,DATATABLE_COLUMNS_LISTA_FPAGO,DATATABLE_COLUMNS_LISTA_ANULACION,DATATABLE_DC_COMPRA_DETALLE,DATATABLE_COLUMNS_LISTA_ITEMS,DATATABLE_LISTA_ITEMS2,DATATABLE_LISTA_CLIENTE,DATATABLE_LISTA_ITEMS,DATATABLE_COTIZACION_DETALLE} from "./Datatables"
import {STATES_COT_COTIZACION} from "./States";
import {
  Box,
  IconButton,
} from '@mui/material';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBInput,
  MDBTypography,
  MDBTextArea,MDBIcon
} from "mdb-react-ui-kit";
import { imgURLocalImage } from "./Services";
import {ADD_IMAGE,SUCCES_IMAGE,SEARCH_IMAGE,EXIT_IMAGE,PRECIO_IMAGE,YAPPY_IMAGE} from "./Image";
const BodegaModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>BODEGAS</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="bod_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="bod_nombre"
                  onChange={onChange}
                  defaultValue={props.bod_nombre}
                />
                <MDBInput
                  type="text"
                  id="bod_ubicacion"
                  wrapperClass="mb-4"
                  label="Ubicación"
                  name="bod_ubicacion"
                  onChange={onChange}
                  defaultValue={props.bod_ubicacion}
                />
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
              <MDBIcon fas icon="ban" className="me-1" /> Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
              <MDBIcon fas icon="save" className="me-1" />  Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ClienteModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
  setFormClientesBlank,
  handleOnChangeCheckBox
}) => {
  return (
    <React.Fragment>
      {/* <MDBBtn onClick={toggleShow}>BODEGA</MDBBtn> */}
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>CLIENTES</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);setFormClientesBlank()}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="cli_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="cli_nombre"
                  onChange={onChange}
                  defaultValue={props.cli_nombre}
                />
                <MDBInput
                  id="cli_direccion"
                  wrapperClass="mb-4"
                  label="Dirección"
                  name="cli_direccion"
                  onChange={onChange}
                  defaultValue={props.cli_direccion}
                />
                <MDBInput
                  type="text"
                  id="cli_telefono"
                  wrapperClass="mb-4"
                  label="Teléfono"
                  name="cli_telefono"
                  onChange={onChange}
                  defaultValue={props.cli_telefono}
                />
                <MDBInput
                  id="cli_telefono2"
                  wrapperClass="mb-4"
                  label="Teléfono 2"
                  name="cli_telefono2"
                  onChange={onChange}
                  defaultValue={props.cli_telefono2}
                />
                <MDBInput
                  type="text"
                  id="cli_ruc"
                  wrapperClass="mb-4"
                  label="RUC"
                  name="cli_ruc"
                  onChange={onChange}
                  defaultValue={props.cli_ruc}
                />
                <MDBInput
                  type="number"
                  min={1}
                  max={99}
                  id="cli_dv"
                  wrapperClass="mb-4"
                  label="DV"
                  name="cli_dv"
                  onChange={onChange}
                  defaultValue={props.cli_dv}
                />
                <MDBInput
                  type="email"
                  id="cli_email"
                  wrapperClass="mb-4"
                  label="E-MAIL"
                  name="cli_email"
                  onChange={onChange}
                  defaultValue={props.cli_email}
                />
                 <MDBInput
                  type="cli_limitc"
                  id="cli_limitc"
                  wrapperClass="mb-4"
                  label="Monto de crédito"
                  name="cli_limitc"
                  onChange={onChange}
                  defaultValue={props.cli_limitc}
                />
              <div className="form-check form-check-inline mt-1">
                <input className="form-check-input" type="checkbox" name="cli_credito" id="inlineRadioPrecio"
                onChange={handleOnChangeCheckBox}  value={props.cli_credito} checked={props.cli_credito==="1"?true:false}/>
                <label className="form-check-label" htmlFor="inlineRadioPrecio">Límite de crédito</label>
              </div>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                  <MDBBtn color="danger" onClick={() => {toggle(false);setFormClientesBlank()}}>
                  <MDBIcon fas icon="ban" className="me-1" />    Cerrar
                  </MDBBtn>
                  <MDBBtn   onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
                  <MDBIcon fas icon="save" className="me-1" />   Guardar
                  </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const ProveedorModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>PROVEEDORES</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="prov_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="prov_nombre"
                  onChange={onChange}
                  defaultValue={props.prov_nombre}
                />
                <MDBInput
                  id="prov_direccion"
                  wrapperClass="mb-4"
                  label="Dirección"
                  name="prov_direccion"
                  onChange={onChange}
                  defaultValue={props.prov_direccion}
                />
                <MDBInput
                  type="text"
                  id="prov_telefono"
                  wrapperClass="mb-4"
                  label="Teléfono"
                  name="prov_telefono"
                  onChange={onChange}
                  defaultValue={props.prov_telefono}
                />
                <MDBInput
                  id="prov_ruc"
                  wrapperClass="mb-4"
                  label="RUC"
                  name="prov_ruc"
                  onChange={onChange}
                  defaultValue={props.prov_ruc}
                />
                <MDBInput
                  type="text"
                  id="prov_dv"
                  wrapperClass="mb-4"
                  label="DV"
                  name="prov_dv"
                  onChange={onChange}
                  defaultValue={props.prov_dv}
                />
                <MDBInput
                  type="email"
                  id="prov_email"
                  wrapperClass="mb-4"
                  label="E-MAIL"
                  name="prov_email"
                  onChange={onChange}
                  defaultValue={props.prov_email}
                />
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
              <MDBIcon fas icon="ban" className="me-1" />   Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
              <MDBIcon fas icon="save" className="me-1" />      Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const DeleteModal = ({
  props,
  isOpen,
  toggle,
  toggleDelete,
  text, error
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog className="modal-delete">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>AVISO</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
              <Label className="fw-bold">{props}</Label><br></br>
<Label>{text}</Label>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
              <MDBIcon fas icon="ban" className="me-1" />     Cancelar
              </MDBBtn>
              <MDBBtn onClick={() => toggleDelete({})}>
              <MDBIcon fas icon="trash" className="me-1 " />    SI
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const MarcaModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>MARCAS</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="mar_descripcion"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="mar_descripcion"
                  onChange={onChange}
                  defaultValue={props.mar_descripcion}
                />
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
              <MDBIcon fas icon="trash" className="me-1 " />  Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
              <MDBIcon fas icon="save" className="me-1 " />     Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const CategoriaModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>CATEGORIAS</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="cat_descripcion"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="cat_descripcion"
                  onChange={onChange}
                  defaultValue={props.cat_descripcion}
                />
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
              <MDBIcon fas icon="ban" className="me-1 " />    Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
              <MDBIcon fas icon="save" className="me-1 " />   Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const RestauranteModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
  onChangeDropdown,
  handleOnChangeCheckBox
}) => {
    var valueSelected = ""
    var valueSelectedPrint = ""

    // dataDropdownTipo.find((data,i)=>{
    //     if(data.value ===props.usr_perfil){
    //         valueSelected = data.label
    //         return
    //     }
    //   }
    //   )
    //   dataDropdownPrint.find((data,i)=>{
    //     if(data.value ===props.usr_print){
    //       valueSelectedPrint = data.label
    //         return
    //     }
    //   }
    //   )
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Restaurante</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="usr_usuario"
                  wrapperClass="mb-4"
                  label="* Usuario"
                  name="usr_usuario"
                  onChange={onChange}
                  defaultValue={props.usr_usuario}
                />
                {/* <MDBInput
                  id="usr_clave"
                  wrapperClass="mb-4"
                  label="* Clave"
                  name="usr_clave"
                  onChange={onChange}
                  defaultValue={props.usr_clave}
                />
                <MDBInput
                  id="usr_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="usr_nombre"
                  onChange={onChange}
                  defaultValue={props.usr_nombre}
                />
                  <MDBTypography variant="h6 mt-2">* Tipo de usuario</MDBTypography>
                    <Select
                      options={dataDropdownTipo}
                      onChange={onChangeDropdown}
                      placeholder={valueSelected}
                    />
                  <MDBTypography variant="h6 mt-2">* Imprimir</MDBTypography>
                  <Select
                    options={dataDropdownPrint}
                    onChange={onChangeDropdown}
                    placeholder={valueSelectedPrint}
                  />

                  <MDBTypography variant="h6 mt-2">* Permisos</MDBTypography>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_ajustes" id="inlineRadioAjustes"
                    onChange={handleOnChangeCheckBox}  value={props.usr_ajustes} checked={props.usr_ajustes==="1"?true:false} disabled={props.usr_perfil==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioAjustes">Ajustes</label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_factura" id="inlineRadioFactura"
                    onChange={handleOnChangeCheckBox}  value={props.usr_factura} checked={props.usr_factura==="1"?true:false} />
                    <label className="form-check-label" htmlFor="inlineRadioFactura">Factura</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_inventario" id="inlineInventario" value={props.usr_inventario}  
                    onChange={handleOnChangeCheckBox}  checked={props.usr_inventario==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioInventario">Inventario</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_cotizacion" id="inlineRadioCotizacion" value={props.usr_cotizacion} 
                      onChange={handleOnChangeCheckBox} checked={props.usr_cotizacion==="1"?true:false} />
                    <label className="form-check-label" htmlFor="inlineRadioCotizacion">Cotizacion</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_articulo" id="inlineRadioArticulos" value={props.usr_articulo} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_articulo==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioArticulo">Articulos</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_reporte" id="inlineRadioReporte" value={props.usr_reporte} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_reporte==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Reportes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_apartado" id="inlineRadioReporte" value={props.usr_apartado} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_apartado==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Apartado</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_clientes" id="inlineRadioReporte" value={props.usr_clientes} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_clientes==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Clientes</label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_precio" id="inlineRadioPrecio"
                    onChange={handleOnChangeCheckBox}  value={props.usr_precio} checked={props.usr_precio==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioPrecio">Puede editar precios</label>
                  </div> */}
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
                Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
                Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const UsuariosModal = ({
  props,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  error,
  onChangeDropdown,
  handleOnChangeCheckBox
}) => {
    var valueSelected = ""
    var valueSelectedPrint = ""

    dataDropdownTipo.find((data,i)=>{
        if(data.value ===props.usr_perfil){
            valueSelected = data.label
            return
        }
      }
      )
      dataDropdownPrint.find((data,i)=>{
        if(data.value ===props.usr_print){
          valueSelectedPrint = data.label
            return
        }
      }
      )
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>USUARIOS</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <MDBInput
                  id="usr_usuario"
                  wrapperClass="mb-4"
                  label="* Usuario"
                  name="usr_usuario"
                  onChange={onChange}
                  defaultValue={props.usr_usuario}
                />
                <MDBInput
                  id="usr_clave"
                  wrapperClass="mb-4"
                  label="* Clave"
                  name="usr_clave"
                  onChange={onChange}
                  defaultValue={props.usr_clave}
                />
                <MDBInput
                  id="usr_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="usr_nombre"
                  onChange={onChange}
                  defaultValue={props.usr_nombre}
                />
                  <MDBTypography variant="h6 mt-2">* Tipo de usuario</MDBTypography>
                    <Select
                      options={dataDropdownTipo}
                      onChange={onChangeDropdown}
                      placeholder={valueSelected}
                    />
                  <MDBTypography variant="h6 mt-2">* Imprimir</MDBTypography>
                  <Select
                    options={dataDropdownPrint}
                    onChange={onChangeDropdown}
                    placeholder={valueSelectedPrint}
                  />

                  <MDBTypography variant="h6 mt-2">* Permisos</MDBTypography>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_ajustes" id="inlineRadioAjustes"
                    onChange={handleOnChangeCheckBox}  value={props.usr_ajustes} checked={props.usr_ajustes==="1"?true:false} disabled={props.usr_perfil==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioAjustes">Ajustes</label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_factura" id="inlineRadioFactura"
                    onChange={handleOnChangeCheckBox}  value={props.usr_factura} checked={props.usr_factura==="1"?true:false} />
                    <label className="form-check-label" htmlFor="inlineRadioFactura">Factura</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_inventario" id="inlineInventario" value={props.usr_inventario}  
                    onChange={handleOnChangeCheckBox}  checked={props.usr_inventario==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioInventario">Inventario</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_cotizacion" id="inlineRadioCotizacion" value={props.usr_cotizacion} 
                      onChange={handleOnChangeCheckBox} checked={props.usr_cotizacion==="1"?true:false} />
                    <label className="form-check-label" htmlFor="inlineRadioCotizacion">Cotizacion</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_articulo" id="inlineRadioArticulos" value={props.usr_articulo} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_articulo==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioArticulo">Articulos</label>
                  </div>

                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_reporte" id="inlineRadioReporte" value={props.usr_reporte} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_reporte==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Reportes</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_apartado" id="inlineRadioReporte" value={props.usr_apartado} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_apartado==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Apartado</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="checkbox" name="usr_clientes" id="inlineRadioReporte" value={props.usr_clientes} 
                     onChange={handleOnChangeCheckBox}  checked={props.usr_clientes==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioReporte">Clientes</label>
                  </div>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="usr_precio" id="inlineRadioPrecio"
                    onChange={handleOnChangeCheckBox}  value={props.usr_precio} checked={props.usr_precio==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioPrecio">Puede editar precios</label>
                  </div>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
                Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
                Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ArticuloModal = ({
  props,
  propsArtBodega,
  propsDropdown,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  toggleSaveEdit,
  onChange,
  onChangeDropdown,
  onChangeImage,
  images,
  error,
  onRemoveBodega,
  removeImage,
  handleOnChangeCheckBox
}) => {
  const maxNumber = 69;
  var compraLabel = propsDropdown.itbms_compra.find((data)=>{
    if(data.value === props.art_itbm_cod_c)
      return data
  }
  )
  var ventaLabel = propsDropdown.itbms_venta.find((data)=>{
    if(data.value === props.art_itbm_cod_v)
      return data
  }
  )
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Articulo</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <form>
                <Row>
                  <Col xs={8}>
                    <MDBInput
                      id="art_descripcion"
                      wrapperClass="mb-2"
                      label="* Descripcion"
                      name="art_descripcion"
                      onChange={onChange}
                      defaultValue={props.art_descripcion}
                    />
                  </Col>
                  <Col xs={4}>
                  <div className="form-check form-check-inline mt-1">
                    <input className="form-check-input" type="checkbox" name="art_restaurante" id="inlineRadioAjustes"
                    onChange={handleOnChangeCheckBox}  value={props.art_restaurante} checked={props.art_restaurante==="1"?true:false}/>
                    <label className="form-check-label" htmlFor="inlineRadioAjustes">* Articulo restaurante</label>
                  </div>
                  </Col>
                  <Col xs={4}>
                    <MDBInput
                      id="art_upc"
                      wrapperClass="mb-2"
                      label="* CODIGO"
                      name="art_upc"
                      onChange={onChange}
                      defaultValue={props.art_upc}
                      // disabled={basicModalEdit?true:false}
                    />
                  </Col>
                  <Col xs={4}>
                    <MDBInput
                      id="art_referencia"
                      wrapperClass="mb-2"
                      label="Referencia"
                      name="art_referencia"
                      onChange={onChange}
                      defaultValue={props.art_referencia}
                    />
                  </Col>
                  <Col xs={4}>
                    <MDBInput
                      id="art_serie"
                      wrapperClass="mb-2"
                      label="Serie"
                      name="art_serie"
                      onChange={onChange}
                      defaultValue={props.art_serie}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 text-center">
                  <Col xs={6}>
                    <MDBTypography variant="h6">* Proveedor</MDBTypography>
                    <Select
                      options={propsDropdown.proveedor}
                      onChange={onChangeDropdown}
                      placeholder={props.prov_nombre}
                    />
                  </Col>
                  <Col xs={6}>
                    <MDBTypography variant="h6">* Categorias</MDBTypography>
                    <Select
                      options={propsDropdown.categorias}
                      onChange={onChangeDropdown}
                      placeholder={props.cat_descripcion}
                    />
                  </Col>
                </Row>
                <Row className="mt-2 text-center mb-2">
                  <Col xs={6}>
                    <MDBTypography variant="h6">* Bodegas</MDBTypography>
                    <Select
                      options={propsDropdown.bodegas}
                      onChange={onChangeDropdown}
                      value={"Seleccione para agregar bodega"}
                      placeholder={"Seleccione para agregar bodega"}
                    />
                  </Col>
                  <Col xs={6}>
                    <MDBTypography variant="h6">* Marca</MDBTypography>
                    <Select
                      options={propsDropdown.marcas}
                      onChange={onChangeDropdown}
                      placeholder={props.mar_descripcion}
                    />
                  </Col>
                  <Col xs={12}>
                    <Row>
                    {
                      propsArtBodega.map((data,id)=>{
                        return <React.Fragment>
                          <Col >
                          <MDBBtn type="button" className="margin-btn-bodega mt-2" color='danger'
                           onClick={() => onRemoveBodega(data)}
                            key ={id}>
                            {data.bod_nombre}
                          </MDBBtn>
                          </Col>
                          
                        </React.Fragment>
                      })
                    }
                    </Row>
                    
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <MDBInput
                      id="art_costo"
                      wrapperClass="mb-2"
                      label="Costo"
                      name="art_costo"
                      onChange={onChange}
                      type="number"
                      defaultValue={props.art_costo}
                    />
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="art_precio"
                      wrapperClass="mb-2"
                      label="* Precio 1"
                      name="art_precio"
                      type="number"
                      onChange={onChange}
                      defaultValue={props.art_precio}
                    />
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="art_precio2"
                      wrapperClass="mb-2"
                      label="Precio 2"
                      name="art_precio2"
                      type="number"
                      onChange={onChange}
                      defaultValue={props.art_precio2}
                    />
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="art_precio3"
                      wrapperClass="mb-2"
                      label="Precio 3"
                      name="art_precio3"
                      type="number"
                      onChange={onChange}
                      defaultValue={props.art_precio3}
                    />
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="art_linea_roja"
                      wrapperClass="mb-2"
                      label="Linea roja"
                      name="art_linea_roja"
                      type="number"
                      onChange={onChange}
                      defaultValue={props.art_linea_roja}
                    />
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="inv_qty"
                      wrapperClass="mb-2"
                      label="Cantidad"
                      name="inv_qty"
                      type="number"
                      onChange={onChange}
                      defaultValue={props.inv_qty}
                      disabled={true}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col xs={4}>
                    {/* <MDBInput
                      id="art_img"
                      wrapperClass="mb-4"
                      label="Imagen"
                      name="art_img"
                      onChange={onChange}
                      value={props.art_img}
                    /> */}
                    <div className="App">
                      <ImageUploading
                        multiple={false}
                        value={images}
                        onChange={onChangeImage}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          // write your building UI
                          <div className="upload__image-wrapper">
                            <Row className="mb-2">
                              <Col xs={12} className="text-center">
                                <MDBBtn
                                  type="button"
                                  style={isDragging ? { color: "red" } : undefined}
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Click or Drop here
                                </MDBBtn>
                              </Col>
                            </Row>
                            {images[0] === undefined && props.art_imgName.length > 0 ? (
                              <Row>
                                <Col xs={12} className="text-center">
                                  <img
                                    src={imgURLocalImage + props.art_imgName}
                                    alt=""
                                    width="130"
                                  />
                                </Col>
                                <Col xs={12} className="text-center">
                                    <MDBBtn type="button" onClick={() => removeImage(props.art_id)}>
                                      Remover
                                    </MDBBtn>
                                  </Col>
                              </Row>
                            ) : null}

                            {imageList.map((image, index) => (
                              <div key={index} className="image-item">
                                <Row>
                                  <Col xs={12} className="text-center">
                                    <img src={image["data_url"]} alt="" width="130" />
                                  </Col>
                                </Row>

                                <Row className="image-item__btn-wrapper mt-2">
                                  <Col xs={6} className="text-center">
                                    <MDBBtn type="button" onClick={() => onImageUpdate(index)}>
                                      Actualizar
                                    </MDBBtn>
                                  </Col>
                                  <Col xs={6} className="text-center">
                                    <MDBBtn type="button" onClick={() => onImageRemove(index)}>
                                      Remover
                                    </MDBBtn>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                  </Col>
                  <Col xs={4}>
                  <MDBTypography variant="h6">* Compra</MDBTypography>
                  <Select
                      options={propsDropdown.itbms_compra}
                      onChange={onChangeDropdown}
                      placeholder={compraLabel=== undefined?null:compraLabel.label}
                      maxMenuHeight={150}
                    />
                    {/* <MDBInput
                      id="art_itbm_venta"
                      wrapperClass="mb-4"
                      label="ITBM Venta"
                      name="art_itbm_venta"
                      type="number"
                      onChange={onChange}
                      value={props.art_itbm_venta}
                    /> */}
                  </Col>
                  <Col xs={4}>
                  <MDBTypography variant="h6">* Venta</MDBTypography>
                  <Select
                      options={propsDropdown.itbms_venta}
                      onChange={onChangeDropdown}
                      placeholder={ventaLabel=== undefined?null:ventaLabel.label}
                      maxMenuHeight={150}
                    />
                
                    {/* <MDBInput
                      id="art_itbm_compra"
                      wrapperClass="mb-4"
                      type="number"
                      label="ITBM Compra"
                      name="art_itbm_compra"
                      onChange={onChange}
                      value={props.art_itbm_compra}
                    /> */}
                  </Col>
                </Row>
              </form>
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => toggle(false)}>
                Cerrar
              </MDBBtn>
              <MDBBtn onClick={() => (basicModalEdit ? toggleSaveEdit() : toggleSave())}>
                Guardar
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const InventarioModal = ({
  props,
  dataCompraDetalle,
  propsDCDetail,
  formDropdown,
  propsDropdown,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  onChange,
  onChangeDC_detail,
  handleKeyPress,
  onChangeDropdown,
  onBlurs,
  error,
  error_upc,
  isSaveI,
  deleteRow,
  setOBJCompraDetalle,
  fetchDCCompra,
  update_dcd_compra_detalle_row,
  toggleSaveEncabezado,
  dataItems,
  selectItem,
  setFormCompraDetalle
}) => {
  const [isTabItems, setTabItems] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  var enableTab = true
  if(props.dc_docn.length>0 && props.dc_fecha.length>0 && formDropdown.prov_id.length>0){
    enableTab = false
  }
  const ref = useRef(null);
  if (propsDCDetail.art_descripcion.length>0) {
      // ref.current.focus();
   }

var subtotal=0;
var itbms =0;
var total = 0;

dataCompraDetalle.map((row,i)=>{
  subtotal = Number(subtotal) + Number((Number(row.dcd_cantidad)*Number(row.dcd_precio)))
  itbms = Number(itbms) + Number(row.dcd_itbms)
 total = Number(subtotal)+Number(itbms)
})

const handleSaveRow = ({
  exitEditingMode,
  row,
  values
}) => {

if(basicModalEdit){
  update_dcd_compra_detalle_row({
    dcd_cantidad:values.dcd_cantidad,
    dcd_id:row.original.dcd_id, 
    dcd_itbms:Number((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*Number(row.original.art_itbm_compra)),
    dcd_total: Number((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*(1+Number(row.original.art_itbm_compra))),
  },row.original.dcd_identificador)
  exitEditingMode();
}
else{
  dataCompraDetalle[row.index] = {
      art_upc:row.original.art_upc,
      art_descripcion: row.original.art_descripcion,
      art_itbm_cod_c:row.original.art_itbm_cod_c,
      art_itbm_compra:row.original.art_itbm_compra,
      dcd_articulo:row.original.dcd_articulo,
      dcd_cantidad: values.dcd_cantidad,
      dcd_descuento:row.original.dcd_descuento,
      dcd_descuento_number:row.original.dcd_descuento_number,
      dcd_id:row.original.dcd_id,
      dcd_itbms: ((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*Number(row.original.art_itbm_compra)).toFixed(2),
      dcd_precio: values.dcd_precio,
      dcd_total: ((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*(1+Number(row.original.art_itbm_compra))).toFixed(2),
    };

    setOBJCompraDetalle([...dataCompraDetalle]);
    exitEditingMode();
}
};

  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="fullscreen" className="modal-full-large">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Factura de compra</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);fetchDCCompra()}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <>
                {isTabItems?<>
<Row>
<Col xs={12} className="modal-add-inventario datatable-factura">
              {dataItems !==undefined?<DATATABLE_LISTA_ITEMS dataItems={dataItems} selectItem={selectItem} setTabItems={setTabItems}/>:<Label>Cargando...</Label>}
                  </Col>
</Row>
                </>:<>
                <form>
                <Row>
                  <Col xs={3}>
                  <MDBTypography variant="h6 mt-2">* N° Documento</MDBTypography>
                    <MDBInput
                      id="dc_docn"
                      wrapperClass="mb-4"
                      label="* N° Documento"
                      name="dc_docn"
                      onChange={onChange}
                      defaultValue={props.dc_docn}
                    />
                  </Col>
                  <Col xs={3}>
                  <MDBTypography variant="h6 mt-2">* Fecha documento</MDBTypography>
                    <MDBInput
                      id="dc_fecha"
                      type="date"
                      wrapperClass="mb-4"
                      // label="* Fecha documento"
                      name="dc_fecha"
                      onChange={onChange}
                      defaultValue={props.dc_fecha}
                    />
                  </Col>
                  <Col xs={6}>
                  <MDBTypography variant="h6 mt-2">*Proveedor</MDBTypography>
                    <Select
                      options={propsDropdown}
                      onChange={onChangeDropdown}
                      placeholder={formDropdown.prov_nombre}
                      maxMenuHeight={150}
                    />
                  </Col>
                  <Col xs={12}>
                    <MDBInput
                      id="dc_descripcion"
                      wrapperClass="mb-4"
                      label="Descripción"
                      name="dc_descripcion"
                      onChange={onChange}
                      defaultValue={props.dc_descripcion}
                      
                    />
                  </Col>
                </Row>
              </form>
              <Row >
              <Col xs={8}>
                    <Label className="error-label-inventario">
                      - {error_upc}
                    </Label>
                  </Col>
              <Col xs={4} className="text-end">
                {
                  basicModalEdit?<MDBBtn className="btn-update-header" onClick={() => ( toggleSaveEncabezado())}>
                  Actualizar encabezado
                </MDBBtn>:null
                }
                  
                  </Col>
                  <Col xs ={12}>
                    <Row>
                    <Col xs={2} className="d-flex">
                  <MDBInput
                      id="art_upc"
                      wrapperClass="mb-4"
                      label="Código"
                      name="art_upc"
                      onChange={onChangeDC_detail}
                      value={propsDCDetail.art_upc}
                      disabled={enableTab}
                      onKeyDown={(e) =>{
                        handleKeyPress(e, props.dcd_articulo);
                      }
                      }
                    />
                      {enableTab===true?null:<img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setTabItems(true)}}/>}
                      {enableTab===false && propsDCDetail.art_upc.length>0?<img src={EXIT_IMAGE} className="src-image" alt="..." onClick={()=>{     
                         setFormCompraDetalle({
                          dcd_id: "",
                          dcd_articulo: "",
                          dcd_precio: "",
                          dcd_cantidad: "1",
                          dcd_itbms: "",
                          dcd_descuento: "",
                          dcd_total: "",
                          dcd_descuento_number: "",
                          art_descripcion: "",
                          art_upc:""
                        });
                      
                      }}/>:null}
                  </Col>
                  <Col xs={6}>
                  <MDBInput
                      id="art_descripcion"
                      wrapperClass="mb-4"
                      label="Descripción artículo"
                      name="art_descripcion"
                      onChange={onChangeDC_detail}
                      value={propsDCDetail.art_descripcion}
                      disabled ={propsDCDetail.art_upc.length>0?false:true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                      id="dcd_precio"
                      wrapperClass="mb-4"
                      label="Precio"
                      name="dcd_precio"
                      // autoFocus ={true}
                      type={"number"}
                      onChange={onChangeDC_detail}
                      value={propsDCDetail.dcd_precio}
                      disabled= {propsDCDetail.art_upc.length>0?false:true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                      id="dcd_cantidad"
                      wrapperClass="mb-4"
                      label="Cantidad"
                      name="dcd_cantidad"
                      autoFocus ={true}
                      type={"number"}
                      min={"1"}
                      ref={ref}
                      disabled ={propsDCDetail.art_upc.length>0?false:true}
                      onChange={onChangeDC_detail}
                      value={propsDCDetail.art_descripcion.length>0?propsDCDetail.dcd_cantidad:""}
                      onKeyDown={(e) =>
                        handleKeyPress(e, propsDCDetail.dcd_cantidad)
                      }
                    />
                  </Col>
                <Col className="modal-add-inventario datatable-factura">
                <DATATABLE_DC_COMPRA_DETALLE deleteRow ={deleteRow} onBlurs={onBlurs} enableTab={enableTab} handleSaveRow={handleSaveRow} dataCompraDetalle={dataCompraDetalle} />
                </Col>


              </Row>
              <Row className="mt-3">
                  <Col xs={6}>
                  </Col>
                <Col xs={2}>
                <MDBInput
                 className="input-iventario-totales"
                      id="dcd_i_subtotal"
                      wrapperClass="mb-4"
                      label="SUBTOTAL"
                      name="dcd_i_subtotal"
                      autoFocus ={true}
                      value={
                        enableTab?[]:subtotal.toFixed(2)
                      }
                      disabled={true}
                    />
                </Col>
                <Col xs={2}>
                <MDBInput
                 className="input-iventario-totales"
                      id="dcd_i_itbms"
                      wrapperClass="mb-4"
                      label="ITBMS"
                      name="dcd_i_itbms"
                      autoFocus ={true}
                      value={
                        enableTab?[]:itbms.toFixed(2)
                      }
                      disabled={true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                  className="input-iventario-totales"
                      id="dcd_i_total"
                      wrapperClass="mb-4"
                      label="TOTAL"
                      name="dcd_i_total"
                      autoFocus ={true}
                      value={                 enableTab?[]:total.toFixed(2)
                      }
                      disabled={true}
                    />
                  </Col>
                    </Row>
                  </Col>
                </Row>       
                </>}   
                </>
            </MDBModalBody>
            <MDBModalFooter>
            {isTabItems?<>
              <Row>
                <Col xs={12} className="text-center">
                <MDBBtn color="danger" onClick={() => {setTabItems(false)}}>
                <MDBIcon fas icon="ban" className="me-1"  />   Cerrar
              </MDBBtn>
                </Col>
              </Row>
            </>:<>
            <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
                <Col xs={12} className="text-center">
                 {isSaveI ? <Label className="success-label" >Guardado</Label> : ""}
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => {toggle(false);fetchDCCompra();}}>
              <MDBIcon fas icon="ban" className="me-1"  />    Cerrar
              </MDBBtn>
              {basicModalEdit?"":              <MDBBtn onClick={() => ( toggleSave())}>
              <MDBIcon fas icon="save" className="me-1"  />  Guardar
              </MDBBtn>}

            </>}

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const RecuentoModal = ({
  props,
  dataRecuentoDetalle,
  formRecuentoDetalle,
  isOpen,
  basicModalEditRe,
  toggle,
  toggleSave,
  onChange,
  onChangeRed_detail,
  handleKeyPress,
  onBlurs,
  error,
  error_upc,
  isSaveI,
  deleteRow,
  setOBJRecuentoDetalle,
  fetchRecuento,
  dataItems,
  update_red_recuento_detalle_row_re,
  toggleSaveEncabezado,
  dataRecuento,
  selectItem,
  setFormRecuentoDetalle
}) => {
  const [isTabItems, setTabItems] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };

  var enableTab = true
  if(props.re_fecha.length){
    enableTab = false
  }
  const ref = useRef(null);
  if (formRecuentoDetalle.art_descripcion.length>0) {
      // ref.current.focus();
   }

const handleSaveRow = ({
  exitEditingMode,
  row,
  values
}) => {

if(basicModalEditRe){
  update_red_recuento_detalle_row_re({
    red_cantidad:values.red_cantidad,
    red_id:row.original.red_id, 
  },row.original.red_identificador)
  exitEditingMode();
}
else{
  dataRecuentoDetalle[row.index] = {
      art_upc:row.original.art_upc,
      red_articulo:row.original.red_articulo,
      red_cantidad: values.red_cantidad,
      red_id:row.original.red_id,
    };

    setOBJRecuentoDetalle([...dataRecuentoDetalle]);
    exitEditingMode();
}
};

  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="fullscreen" className="modal-full-large">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Inventario # {props.re_id}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);fetchRecuento()}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
                <>
                {isTabItems?<>
                  <Row>
                    <Col xs={12} className="modal-add-inventario datatable-factura">
                      {dataItems !==undefined?
                      <DATATABLE_COLUMNS_LISTA_ITEMS dataItems={dataItems} selectItem={selectItem} setTabItems={setTabItems}/> :<Label>Cargando...</Label>}
                  </Col>
                </Row>
                </>:<>
                    <form>
                      <Row>
                        <Col xs={2}>
                        <MDBTypography variant="h6 mt-2">* Fecha del conteo</MDBTypography>
                          <MDBInput
                            id="re_fecha"
                            type="date"
                            wrapperClass="mb-4"
                            // label="* Fecha documento"
                            name="re_fecha"
                            onChange={onChange}
                            defaultValue={props.re_fecha}
                          />
                        </Col>
                        <Col xs={10}>
                        <MDBTypography variant="h6 mt-2">Descripcion</MDBTypography>
                          <MDBInput
                            id="re_descripcion"
                            wrapperClass="mb-4"
                            label="Descripcion"
                            name="re_descripcion"
                            onChange={onChange}
                            
                            defaultValue={props.re_descripcion}
                            
                          />
                        </Col>
                      </Row>
                  </form>
                  <Row>
                    <Col xs={8}>
                      <Label className="error-label-inventario">
                        - {error_upc}
                      </Label>
                    </Col>
                    <Col xs={4} className="text-end">
                      {
                        basicModalEditRe?<MDBBtn className="btn-update-header" onClick={() => ( toggleSaveEncabezado())}>
                        Actualizar encabezado
                      </MDBBtn>:null
                      }
                    </Col>
                    <Col xs ={12}>
                      <Row>
                        <Col xs={2} className="d-flex">
                          <MDBInput
                              id="art_upc"
                              wrapperClass="mb-4"
                              label="Codigo"
                              name="art_upc"
                              onChange={onChangeRed_detail}
                              value={formRecuentoDetalle.art_upc}
                              disabled={enableTab}
                              onKeyDown={(e) =>{
                                handleKeyPress(e, props.dcd_articulo);
                              }
                              }
                            />
                          {enableTab===true?null:<img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setTabItems(true)}}/>}
                          {enableTab===false && formRecuentoDetalle.art_upc.length>0?<img src={EXIT_IMAGE} className="src-image" alt="..." onClick={()=>{     
                            setFormRecuentoDetalle({
                              red_id: "",
                              red_articulo: "",
                              red_cantidad: "1",
                              red_identificador: "",
                              art_upc: "",
                              art_descripcion: "",
                            });
                          
                          }}/>:null}
                        </Col>
                        <Col xs={8}>
                        <MDBInput
                            id="art_descripcion"
                            wrapperClass="mb-4"
                            label="Descripcion articulo"
                            name="art_descripcion"
                            disabled={true}
                            onChange={onChangeRed_detail}
                            value={formRecuentoDetalle.art_descripcion}
                          />
                        </Col>
                        <Col xs={2}>
                        <MDBInput
                            id="red_cantidad"
                            wrapperClass="mb-4"
                            label="Cantidad"
                            name="red_cantidad"
                            autoFocus ={true}
                            type={"number"}
                            min={"1"}
                            ref={ref}
                            disabled ={formRecuentoDetalle.art_upc.length>0?false:true}
                            onChange={onChangeRed_detail}
                            value={formRecuentoDetalle.art_descripcion.length>0?formRecuentoDetalle.red_cantidad:""}
                            onKeyDown={(e) =>
                              handleKeyPress(e, formRecuentoDetalle.red_cantidad)
                            }
                          />
                        </Col>
                        <Col className="modal-add-inventario datatable-factura">
                        <DATATABLE_COLUMNS_RENCUENTO  enableTab={enableTab} dataRecuentoDetalle ={dataRecuentoDetalle} handleSaveRow={handleSaveRow} deleteRow={deleteRow}/>
                        </Col>
                    </Row> 
                    </Col>
                  </Row> 
                  
                </>}   
                </>
            </MDBModalBody>
            <MDBModalFooter>
            {isTabItems?<>
              <Row>
                <Col xs={12} className="text-center">
                <MDBBtn color="danger" onClick={() => {setTabItems(false)}}>
                Cerrar
              </MDBBtn>
                </Col>
              </Row>
            </>:<>
            <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
                <Col xs={12} className="text-center">
                 {isSaveI ? <Label className="success-label" >Guardado</Label> : ""}
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => {toggle(false);fetchRecuento();}}>
                Cerrar
              </MDBBtn>
              {basicModalEditRe?"":              <MDBBtn onClick={() => ( toggleSave())}>
                Guardar
              </MDBBtn>}

            </>}

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ApartadoModal = ({
  props,
  dataapartadoDetalle,
  propsDCDetail,
  isOpen,
  basicModalEdit,
  toggle,
  toggleSave,
  onChange,
  onChangeap_detail,
  handleKeyPress,
  onBlurs,
  error,
  error_upc,
  isSaveI,
  deleteRow,
  setOBJapartadoDetalle,
  fetchDCapartado,
  update_apd_apartado_detalle_row,
  toggleSaveEncabezado,
  dataClientes,
  selectCliente,
  formClientes
}) => {
  const [isOpenPanelCliente, setPanelCliente] = useState(false);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: '#fff',
      borderColor: '#9e9e9e',
      minHeight: '30px',
      height: '30px',
      boxShadow: state.isFocused ? null : null,
    }),

    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '30px',
    }),
  };
  var enableTab = true
  if(props.ap_docn.length>0 && props.ap_fecha.length>0){
    enableTab = false
  }
  const ref = useRef(null);
  if (propsDCDetail.art_descripcion.length>0) {
      // ref.current.focus();
   }

var subtotal=0;
var itbms =0;
var total = 0;

dataapartadoDetalle.map((row,i)=>{
  subtotal = Number(subtotal) + Number((Number(row.dcd_cantidad)*Number(row.dcd_precio)))
  itbms = Number(itbms) + Number(row.dcd_itbms)
 total = Number(subtotal)+Number(itbms)
})

const handleSaveRow = ({
  exitEditingMode,
  row,
  values
}) => {

if(basicModalEdit){
  update_apd_apartado_detalle_row({
    dcd_cantidad:values.dcd_cantidad,
    dcd_id:row.original.dcd_id, 
    dcd_itbms:Number((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*Number(row.original.art_itbm_compra)),
    dcd_total: Number((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*(1+Number(row.original.art_itbm_compra))),
  },row.original.apd_identificador)
  exitEditingMode();
}
else{
  dataapartadoDetalle[row.index] = {
      art_upc:row.original.art_upc,
      art_descripcion: row.original.art_descripcion,
      art_itbm_compra:row.original.art_itbm_compra,
      dcd_articulo:row.original.dcd_articulo,
      dcd_cantidad: values.dcd_cantidad,
      dcd_itbms: ((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*Number(row.original.art_itbm_compra)).toFixed(2),
      dcd_precio: values.dcd_precio,
      dcd_total: ((Number(values.dcd_cantidad)*Number(row.original.dcd_precio))*(1+Number(row.original.art_itbm_compra))).toFixed(2),
    };

    setOBJapartadoDetalle([...dataapartadoDetalle]);
    exitEditingMode();
}
};

  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="fullscreen" className="modal-full-large">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Apartado</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);fetchDCapartado()}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              {isOpenPanelCliente?<> 
              <Col xs={12}>
              <MDBBtn className="btn-close" color="none" onClick={() => {setPanelCliente(false)}}></MDBBtn>
              </Col>
              <Col className="modal-add-inventario">
              <MaterialReactTable 
                muiTableBodyRowProps={({row}) => ({
                  onClick: () => {
                    selectCliente(row.original)
                  }
                  // selected: selectCliente[row],
                  // sx: {
                  //   cursor: 'pointer'
                  // }
                })} 
                state={{
                  selectCliente
                }}
                      // enableEditing={true}
                      // editDisplayMode="cell"
                      // enableRowActions={true}
                      positionActionsColumn="last"
                      // onEditingRowSave={handleSaveRow}
                      enableGlobalFilter={true} 
                      muiTableBodyRowformFactura={({ row }) => {
                        return {
                          sx: {
                            backgroundColor:
                              row.original.fac_fecha_procesado !== "0000-00-00 00:00:00"
                                ? "rgba(219, 237, 220, 0.78)"
                                : "",
                            fontWeight: "bold",
                          },
                        };
                      }}
                      enableFullScreenToggle={false}
                      // styles={customStyles}
                      enableDensityToggle={false}
                      positionGlobalFilter="left"
                      columns={COLUMNS_LISTA_CLIENTE()}
                      data={ dataClientes}
                      enableTopToolbar={true}
                      muiTableContainerformFactura={{
                        sx: {
                          maxHeight: "290px",
                        },
                      }}
                    />
                  </Col></>:<>             <form>
                <Row>
                  <Col>
                  <MDBInput
                      id="cli_ruc"
                      wrapperClass="mb-4"
                      label="CLIENTE"
                      name="cli_ruc"
                      value={formClientes.cli_nombre + " " +formClientes.cli_dv}
                      disabled={true}
                    />
                    <img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setPanelCliente(true);}}/>
                  </Col>
                  <Col xs={12}>
                    <MDBInput
                      id="dc_descripcion"
                      wrapperClass="mb-4"
                      label="Descripcion"
                      name="dc_descripcion"
                      onChange={onChange}
                      defaultValue={props.dc_descripcion}
                      autoFocus ={true}
                    />
                  </Col>
                </Row>
              </form>
              <Row >
              <Col xs={8}>
                    <Label className="error-label-inventario">
                      - {error_upc}
                    </Label>
                  </Col>
              <Col xs={4} className="text-end">
                {
                  basicModalEdit?<MDBBtn className="btn-update-header" onClick={() => ( toggleSaveEncabezado())}>
                  Actualizar encabezado
                </MDBBtn>:null
                }
                  
                  </Col>
                  <Col xs ={12}>
                    <Row>
                    <Col xs={2}>
                  <MDBInput
                      id="art_upc"
                      wrapperClass="mb-4"
                      label="Codigo"
                      name="art_upc"
                      onChange={onChangeap_detail}
                      value={propsDCDetail.art_upc}
                      disabled={enableTab}
                      onKeyDown={(e) =>{
                        handleKeyPress(e, props.dcd_articulo);
                      }
                      }
                    />
                  </Col>
                  <Col xs={6}>
                  <MDBInput
                      id="art_descripcion"
                      wrapperClass="mb-4"
                      label="Descripcion articulo"
                      name="art_descripcion"
                      onChange={onChangeap_detail}
                      value={propsDCDetail.art_descripcion}
                      disabled ={propsDCDetail.art_upc.length>0?false:true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                      id="dcd_precio"
                      wrapperClass="mb-4"
                      label="Precio"
                      name="dcd_precio"
                      autoFocus ={true}
                      type={"number"}
                      onChange={onChangeap_detail}
                      value={propsDCDetail.dcd_precio}
                      disabled= {propsDCDetail.art_upc.length>0?false:true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                      id="dcd_cantidad"
                      wrapperClass="mb-4"
                      label="Cantidad"
                      name="dcd_cantidad"
                      type={"number"}
                      min={"1"}
                      ref={ref}
                      disabled ={propsDCDetail.art_upc.length>0?false:true}
                      onChange={onChangeap_detail}
                      value={propsDCDetail.dcd_cantidad}
                      onKeyDown={(e) =>
                        handleKeyPress(e, propsDCDetail.dcd_cantidad)
                      }
                    />
                  </Col>
                <Col className="modal-add-inventario">
                <MaterialReactTable
                enableEditing = {true}
                editDisplayMode="cell" 
                enableRowActions= {true}
                positionActionsColumn = 'last'
                onEditingRowSave={handleSaveRow}
          muiTableBodyRowProps={({row}) => {
            return ({
            sx: {
               backgroundColor: row.original.dc_fecha_procesado !== "0000-00-00 00:00:00" ? 'rgba(219, 237, 220, 0.78)' : '',
               fontWeight: 'bold',
            }
          })
        }}
            enableFullScreenToggle={false}
            styles={customStyles}
            enableDensityToggle={false}
            positionGlobalFilter="left"
            columns={COLUMNS_DC_COMPRA_DETALLE(deleteRow,onBlurs)}
            data={enableTab?[]:dataapartadoDetalle}
            enableTopToolbar={false}
            muiTableContainerProps={{
              sx: {
                maxHeight: '290px'
              }
            }}
          />
                </Col>


              </Row>
              <Row className="mt-3">
                  <Col xs={6}>
                  </Col>
                <Col xs={2}>
                <MDBInput
                 className="input-iventario-totales"
                      id="dcd_i_subtotal"
                      wrapperClass="mb-4"
                      label="SUBTOTAL"
                      name="dcd_i_subtotal"
                      autoFocus ={true}
                      value={
                        enableTab?[]:subtotal.toFixed(2)
                      }
                      disabled={true}
                    />
                </Col>
                <Col xs={2}>
                <MDBInput
                 className="input-iventario-totales"
                      id="dcd_i_itbms"
                      wrapperClass="mb-4"
                      label="ITBMS"
                      name="dcd_i_itbms"
                      autoFocus ={true}
                      value={
                        enableTab?[]:itbms.toFixed(2)
                      }
                      disabled={true}
                    />
                  </Col>
                  <Col xs={2}>
                  <MDBInput
                  className="input-iventario-totales"
                      id="dcd_i_total"
                      wrapperClass="mb-4"
                      label="TOTAL"
                      name="dcd_i_total"
                      autoFocus ={true}
                      value={                 enableTab?[]:total.toFixed(2)
                      }
                      disabled={true}
                    />
                  </Col>
                    </Row>
                  </Col>
   
                </Row></>}
 
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
                <Col xs={12} className="text-center">
                 {isSaveI ? <Label className="success-label" >Guardado</Label> : ""}
                </Col>
              </Row>
              <MDBBtn color="danger" onClick={() => {toggle(false);fetchDCapartado();}}>
              <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />      Cerrar
              </MDBBtn>
              {basicModalEdit?"":              <MDBBtn onClick={() => ( toggleSave())}>
              <MDBIcon fas icon="save" className="me-1 class-btn-factura d-flex"  />    Guardar
              </MDBBtn>}

            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ListaClienteModal = ({
  dataCliente,
  isOpen,
  toggle,
  selectCliente,
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Clientes</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              <MaterialReactTable 
                muiTableBodyRowProps={({row}) => ({
                  onClick: () => {
                    selectCliente(row.original)
                  },
                  sx: {
                    backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
                    fontWeight: "bold",
                  },
                })} 
                     
                      muiTableContainerProps={{ sx: { maxHeight: '60vh' } }}
                      layoutMode="grid-no-grow" 
                      enableEditing={false}
                      enableColumnActions={false}
                      editDisplayMode="cell"
                      enableBottomToolbar={true} 
                      enableTopToolbar={true}
                      enableRowActions={false}
                      positionActionsColumn="last"
                      enablePagination={true}
                      enableSorting={false}
                      enableFilters={true} 
                      enableStickyHeader={true}
                      columns={COLUMNS_LISTA_CLIENTE()}
                      data={ dataCliente}
                    />
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const ListaOrden = ({
  dataListOrdenes,
  isOpen,
  toggle,
  selectItemOrdenes
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Lista de ordenes</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              {dataListOrdenes !==undefined?
              <DATATABLE_COLUMNS_LISTA_ORDENES selectItemOrdenes ={selectItemOrdenes} dataListOrdenes ={dataListOrdenes}/>
              :<Label>Cargando...</Label>}
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ListaCotizacion = ({
  dataListCotizacion,
  isOpen,
  toggle,
  selectItemCotizacion
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Lista de cotizaciones</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              {dataListCotizacion !==undefined?
              <DATATABLE_COLUMNS_LISTA_COTIZACION selectItemCotizacion ={selectItemCotizacion} dataListCotizacion ={dataListCotizacion}/>
              :<Label>Cargando...</Label>}
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ListaCreditos = ({
  dataListCreditos,
  isOpen,
  toggle,
  selectCreditosDetalle,
  dataListCreditoDetalle,
  dataListCreditoPago,
  selectCreditosPago,
  addPago,
  deleteCrePago,
  crp_monto_p,
  ObjFrmPago,
  onChangeDropdown,
  formDropDownFPago,
  onChange,
  formListPago,
  setAddFPago,
  dataListpago,
  setFormDropDownFPago,
  setFormListPago,
  setDatalistpago,
  deleteRowFpago,
  setFormPagoCredito,
  formPagoCredito,
  printAbono,
  printFac
}) => {

  const [isCreditoDetalle, setCreditoDetalle] = useState(false);
  const [isCreditoPago, setCreditoPago] = useState(false);
  const [crp_fac_identificador, setFacIdentificador] = useState(false);
  const [crp_cliente, setCrpCliente] = useState(false);
  const [cli_name, setCliName] = useState(false);
  const [isAbonos, toggleAbonos] = useState(false);

  ObjFrmPago = ObjFrmPago.filter((data)=>{
    if(data.frm_code !=="01"){
      return data
    }

  })
  const [monto_adeudado, setMontoAdeudado] = useState(false);
  const handleSaveRow = ({
    exitEditingMode,
    row,
    values
  }) => {

  dataListpago[row.index] = {
    list_fpago: row.original.list_fpago,
    list_fpagoName:row.original.list_fpagoName,
    list_identificador:row.original.list_identificador,
    list_monto:Number(values.list_monto).toFixed(2)
  };
    // setFormClientes({...formClientes,cli_limitc:(Number(formClientes.cli_limitc)+Number(row.original.list_monto))-Number(values.list_monto)})
    setDatalistpago([...dataListpago]);
    exitEditingMode();
  }

  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{isCreditoDetalle?"Facturas":isCreditoPago?"Pagos":"Créditos"}</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              {
              isCreditoDetalle?<>
              <Row>
                <Col xs={4}>
                <MDBInput
                id="cli_name"
                label="Nombre del cliente"
                name="cli_name"
                value={cli_name}
                disabled={true}
                />
                </Col>
                <Col xs={3}>
                <MDBInput
                id="cli_total"
                label="Total"
                name="cli_total"
                value={monto_adeudado}
                disabled={true}
                />
                </Col>
                <Col xs={3}>
                <MDBInput
                id="cli_monto_adeudado"
                label="Saldo adeudado"
                name="cli_monto_adeudado"
                value={(Number(monto_adeudado)-Number(crp_monto_p)).toFixed(2)}
                disabled={true}
                />
                </Col>
                <Col xs={2} className="text-center">
                <IconButton className="float-right-btn"
                      onClick={() => {
                        setCreditoDetalle(false);
                        setFormDropDownFPago({
                          frm_id: "",
                          frm_code:"",
                          frm_descripcion: ""
                        });
                        setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago"}))
                        setDatalistpago([])
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                </Col>
                <Col xs={12} className="mt-3">
                <DATATABLE_COLUMNS_LISTA_CREDITOS_DETALLE setFormPagoCredito={setFormPagoCredito} formPagoCredito={formPagoCredito} setCreditoDetalle={setCreditoDetalle} selectCreditosPago ={selectCreditosPago} 
                dataListCreditoDetalle ={dataListCreditoDetalle} setCreditoPago={setCreditoPago}  setFacIdentificador={setFacIdentificador} printFac={printFac}/>
                </Col>
              </Row>
              </>
              
              :isCreditoPago? 
              <Row>
                <Col xs={2}>
                <MDBInput
                id="crt_saldo"
                label="Total"
                name="crt_saldo"
                type={"number"}
                min={"1"}
                max={10000}
                step='0.01'
                value={Number(formPagoCredito.crd_monto).toFixed(2)}
                disabled={true}
              />
                </Col>
                <Col xs={2}>
                <MDBInput
                id="crp_saldo"
                label="Abonado"
                name="crp_saldo"
                type={"number"}
                min={"1"}
                max={10000}
                step='0.01'
                value={Number(formPagoCredito.crp_monto).toFixed(2)}
                disabled={true}
              />
                </Col>
                <Col xs={2}>
                <MDBInput
                id="crp_saldo"
                label="Saldo"
                name="crp_saldo"
                type={"number"}
                min={"1"}
                max={10000}
                step='0.01'
                value={(Number(formPagoCredito.crd_monto)-Number(formPagoCredito.crp_monto)).toFixed(2)}
                disabled={true}
              />
                </Col>
                <Col xs={2} className="d-flex">
                {isAbonos?               <>
                  <MDBBtn  onClick={() => toggleAbonos(false)} color="success" className="d-flex">
                    <MDBIcon fas icon="credit-card" className="me-1 class-btn-factura d-flex"  />  Pago
                </MDBBtn>

                </>   :                  <MDBBtn  onClick={() => toggleAbonos(true)} color="danger" className="d-flex">
                    <MDBIcon fas icon="plus-circle" className="me-1 class-btn-factura d-flex"  />  Abonos
                </MDBBtn>}
                
                </Col>
                <Col xs={2}>
                {isAbonos? <MDBBtn  onClick={() => printAbono()} className="d-flex">
                    <MDBIcon fas icon="print" className="me-1 class-btn-factura d-flex"  /> Imprimir
                </MDBBtn>:null}
               
                </Col>
                <Col xs={2}>
                <IconButton className="float-right-btn"
                      onClick={() => {
                        setCreditoDetalle(true);
                        setCreditoPago(false);
                        setFormDropDownFPago({
                          frm_id: "",
                          frm_code:"",
                          frm_descripcion: ""
                        });
                        setFormListPago(STATES_FAC_FACTURA({staneName:"formListPago"}))
                        setDatalistpago([])
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                </Col>
                <Col xs={12} className="mt-4">
                {isAbonos?<Row>
                  <Col   xs={12} className="mt-4">
                {  <DATATABLE_COLUMNS_LISTA_CREDITOS_PAGO dataListCreditoPago ={dataListCreditoPago} deleteRow={deleteCrePago}/>}
                </Col>
                </Row>:<Row>
                <Col xs={4}>
                <Select
                options={ObjFrmPago}
                onChange={onChangeDropdown}
                controlShouldRenderValue={formDropDownFPago.frm_descripcion.length>0?true:false}
                maxMenuHeight={180}
              />
                </Col>
                <Col xs={3}>
                    <MDBInput
          
                      id="list_monto"
                      wrapperClass="mb-4"
                      label="* Monto"
                      name="list_monto"
                      onChange={onChange}
                      value={formListPago.list_monto}
                      type="number"
                      disabled={formDropDownFPago.frm_descripcion.length>0?false:true}
                    />
                </Col>
                <Col xs={2}>
               {formDropDownFPago.frm_descripcion.length>0 && Number(formListPago.list_monto)>0 ?<img src={ADD_IMAGE} className="src-image-addpago" alt="..." 
                  onClick={()=>{setAddFPago(); }}
                  />:<img src={ADD_IMAGE} className="src-image-addpago" alt="..." 
                  />}
                  
                </Col>
                <Col xs={3}>
                <MDBBtn onClick={() =>  {addPago({crp_fac_identificador,crp_cliente});toggleAbonos(true)}} color = "success" disabled={dataListpago.length>0?false:true}> 
                Guardar abono
              </MDBBtn>
                </Col>
                <Col xs={12} className="datatable-factura">
                <DATATABLE_COLUMNS_LISTA_FPAGO dataListpago ={dataListpago} handleSaveRow ={handleSaveRow} deleteRow ={deleteRowFpago}/>
                </Col>
                </Row>}
                
                </Col>
              </Row>
              :dataListCreditos !==undefined?
              <DATATABLE_COLUMNS_LISTA_CREDITOS setCliName ={setCliName} setFormPagoCredito={setFormPagoCredito} formPagoCredito={formPagoCredito} setMontoAdeudado={setMontoAdeudado} selectCreditosDetalle ={selectCreditosDetalle} dataListCreditos ={dataListCreditos} setCreditoDetalle={setCreditoDetalle}   setCrpCliente ={setCrpCliente}/>
              :<Label>Cargando...</Label>
              
              }
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const ListaFacModal = ({
  dataListCopia,
  isOpen,
  toggle,
  selectItemFCopia
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Lista de facturas</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              {dataListCopia !==undefined?<MaterialReactTable 
                state={{
                  selectItemFCopia
                }}
                      muiTableBodyRowProps={({ row }) => {
                        return {
                          // onClick: () => {
                          //   selectItemFCopia(row.original)
                          // },
                          sx: {
                            backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
                            fontWeight: "bold",
                          },
                        };
                      }}
                      muiPaginationProps={{
                        showRowsPerPage: false
                      }}
                      muiTableContainerProps={{ sx: { maxHeight: '55vh' } }}
                      enableStickyHeader={true}
                      positionActionsColumn="last"
                      enableGlobalFilter={true} 
                      enableFullScreenToggle={false}
                      enableColumnActions={false}
                      enableEditing={false}
                      enableRowActions={false}
                      enablePagination={true}
                      enableSorting={false}
                      enableFilters={true} 
                      enableDensityToggle={false}
                      positionGlobalFilter="left"
                      enableTopToolbar={true}
                      columns={COLUMNS_LISTA_FACTURA(selectItemFCopia)}
                      data={ dataListCopia}
                    />:<Label>Cargando...</Label>}
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const ListaItemModal = ({
  props,
  isOpen,
  toggle,
  selectItem
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Articulos</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Col className="modal-add-inventario datatable-factura">
              {props !==undefined?<MaterialReactTable 
                      muiTableBodyRowProps={({row}) => ({
                        onClick: () => {
                          selectItem({art_upc:row.original.art_upc,barcode:false})
                        },
                        sx: {
                          backgroundColor:row.id % 2 === 0 ? "#f5f5f5": "",
                        },
                      })} 
                      
                      muiTableContainerProps={{ sx: { maxHeight: '60vh' } }}
                      layoutMode="grid-no-grow" 
                      renderEmptyRowsFallback={() => <Col xs={12} className="col-no-record"><Label className="label-no-record">No hay registros que mostrar</Label></Col>}
                      enableEditing={false}
                      enableColumnActions={false}
                      editDisplayMode="cell"
                      enableBottomToolbar={true} 
                      enableTopToolbar={true}
                      enableRowActions={false}
                      positionActionsColumn="last"
                      enablePagination={true}
                      enableSorting={false}
                      enableFilters={true} 
                      enableStickyHeader={true}
                      columns={COLUMNS_LISTA_ITEMS()}
                      data={ props}
                    />:<Label>Cargando...</Label>}
                  </Col>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const PagarModal = ({
  deleteRow,
  setDatalistpago,
  deleteFpago,
  formDropDownFPago,
  setAddFPago,
  onChangeDropdown,
  dataListpago,
  formListPago,
  ObjFrmPago,
  isOpen,
  toggle,
  toggleSave,
  onChange,
  total,
  error,
  formClientes,
  setFormClientes
}) => {
  var monto =0;
  var showLimitC=false;
  dataListpago.map((data,i)=>{
    monto = monto + Number(data.list_monto)
    if(data.list_fpago==="01"){
      showLimitC= true;
    }
  });
    const handleSaveRow = ({
      exitEditingMode,
      row,
      values
    }) => {

    dataListpago[row.index] = {
      list_fpago: row.original.list_fpago,
      list_fpagoName:row.original.list_fpagoName,
      list_identificador:row.original.list_identificador,
      list_monto:Number(values.list_monto).toFixed(2)
    };
      setFormClientes({...formClientes,cli_limitc:(Number(formClientes.cli_limitc)+Number(row.original.list_monto))-Number(values.list_monto)})
      setDatalistpago([...dataListpago]);
      exitEditingMode();
    }
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl" className="modal-pagar">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Pagar</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Row>
                <Col xs={7} className="d-ruby">
                {
                  ObjFrmPago.map((data,i)=>{
                    return <MDBBtn  onClick={() => onChangeDropdown(data)} color={formDropDownFPago.frm_code === data.frm_code?"primary":"secondary"} className="btn-payment" >
                    {data.icon==="yappy"?<img src={YAPPY_IMAGE} className="yappy-img-payment" alt="..."/>:<MDBIcon fas icon={data.icon} className="me-1 class-btn-factura d-flex"  />}
                    
                     <span className="pl-16">{ data.label}</span> 
                    </MDBBtn>
                  })
                }
                {/* <Select
                options={ObjFrmPago}
                onChange={onChangeDropdown}
                controlShouldRenderValue={formDropDownFPago.frm_descripcion.length>0?true:false}
                maxMenuHeight={180}
              /> */}
                </Col>
                <Col xs={2}>
                    <MDBInput
                      id="list_monto"
                      wrapperClass="mb-4"
                      label="* Monto"
                      name="list_monto"
                      onChange={onChange}
                      value={formListPago.list_monto}
                      type="number"
                      disabled={formDropDownFPago.frm_descripcion.length>0?false:true}
                    />
                </Col>
                <Col xs={1}>
               {formDropDownFPago.frm_descripcion.length>0 && Number(formListPago.list_monto)>0 ?<img src={ADD_IMAGE} className="src-image-addpago" alt="..." 
                  onClick={()=>{setAddFPago();}}
                  />:<img src={ADD_IMAGE} className="src-image-addpago" alt="..." 
                  />}
                  
                </Col>
                <Col xs={2}>
                {formDropDownFPago.frm_code==="01"||showLimitC ?<MDBInput
                      id="cli_limitc"
                      wrapperClass="mb-4"
                      label="Límite de crédito"
                      name="cli_limitc"
                      value={Number(formClientes.cli_limitc).toFixed(2)}
                      type="number"
                      disabled={true}
                    />:null}
                </Col>
                <Col xs={12} className="datatable-factura mt-2">
                <DATATABLE_COLUMNS_LISTA_FPAGO dataListpago ={dataListpago} handleSaveRow ={handleSaveRow} deleteRow ={deleteRow}/>
                </Col>
                <Row className="mt-3">
                {/* <Col xs={1}></Col> */}
                  <Col xs={3}>
                    <MDBInput
                      className="input-iventario-totales"
                      id="total"
                      wrapperClass="mb-4"
                      label="TOTAL"
                      name="total"
                      autoFocus={true}
                      value={total}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={3}>
                    <MDBInput
                      className="input-iventario-totales"
                      id="monto"
                      wrapperClass="mb-4"
                      label="MONTO PAGADO"
                      name="monto"
                      autoFocus={true}
                      value={monto.toFixed(2)}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={3}>
                    <MDBInput
                      className="input-iventario-totales"
                      id="faltante"
                      wrapperClass="mb-4"
                      label="FALTANTE"
                      name="faltante"
                      autoFocus={true}
                      value={((total - monto)>=0?(total - monto):0).toFixed(2)}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={3}>
                    <MDBInput
                      className="input-iventario-totales"
                      id="vuelto"
                      wrapperClass="mb-4"
                      label="VUELTO"
                      name="vuelto"
                      autoFocus={true}
                      value={((monto- total)<=0?0:(monto- total)).toFixed(2)}
                      disabled={true}
                    />
                  </Col>
                </Row>

              </Row>
              
            </MDBModalBody>
            <MDBModalFooter>
              <Row>
                <Col xs={12} className="text-center">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
              </Row>
              <MDBBtn  onClick={() => toggle(false)} color="danger" className="d-flex">
              <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />  Cerrar
              </MDBBtn>
              <MDBBtn onClick={() =>  (parseFloat(total) - parseFloat(monto.toFixed(2)))>0?null:toggleSave({tipo:"1"})} color = {(total - monto)>0?"warning":"success"}>
                Enviar factura
              </MDBBtn>
              <MDBBtn onClick={() =>  (parseFloat(total) - parseFloat(monto.toFixed(2)))>0?null:toggleSave({tipo:"0"})} color = {(total - monto)>0?"warning":"success"}>
                Cotización
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const CotizacionModal = ({
 formCotizacion,
 isOpen,
 toggle,
 onChange,
 error,
 error_upc,
 basicModalEdit,
 formDropDownCliente,
 toggleSaveEncabezado,
 onChangeCot_detail,
 handleKeyPress,
 formCotCotizacionDetalle,
 propsDropdown,
 onChangeDropdown,
 OBJCotizacionDetalle,
 deleteRow,onBlurs,
 update_cotd_cotizacion_detalle_row,
 setOBJCotizacionDetalle,
 fetch_cot_cotizacion,
 toggleSave,
 isSaveI,
 selectCliente,
 dataCliente,
 formClientes,
 onChangeCliente,
 setFormClientesBlank,
 onClickSaveCliente,
 selectItem,
 dataItems,
 isTabAddClientes,
setTabAddClientes,
setFormClientes,
setError,
onClickUpdateClient,
usr_precio,
setPrecio,
formPrecio,
handleOnChangeCheckBox
}) => {
  const inputCantidad =useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [isTabClientes, setTabClientes] = useState(false);
  const [isTabItems, setTabItems] = useState(false);
  var enableTab = true
  var subtotal=0;
  var itbms =0;
  var total = 0;

  if(formCotizacion.cot_fecha.length>0 && formDropDownCliente.cli_id.length>0){
    enableTab = false
  }
  const ref = useRef(null);

  const handleSaveRow = ({
    exitEditingMode,
    row,
    values
  }) => {
  if(basicModalEdit){
    update_cotd_cotizacion_detalle_row({
      cotd_art_descripcion:values.art_descripcion,
      cotd_precio:values.cotd_precio,
      cotd_cantidad:values.cotd_cantidad,
      cotd_id:row.original.cotd_id, 
      cotd_itbms:Number((Number(values.cotd_cantidad)*Number(values.cotd_precio))*Number(row.original.art_itbm_venta)),
      cotd_total: Number((Number(values.cotd_cantidad)*Number(values.cotd_precio))*(1+Number(row.original.art_itbm_venta))),
    },row.original.cotd_identificador)
  }
    OBJCotizacionDetalle[row.index] = {
      art_descripcion: values.art_descripcion,
      art_itbm_cod_v:row.original.art_itbm_cod_v,
      art_itbm_venta:row.original.art_itbm_venta,
      art_upc: row.original.art_upc,
      cotd_articulo: row.original.cotd_articulo,
      cotd_cantidad: values.cotd_cantidad,
      cotd_descuento :row.original.cotd_descuento,
      cotd_descuento_number : row.original.cotd_descuento_number,
      cotd_itbms: ((Number(values.cotd_cantidad)*Number(values.cotd_precio))*Number(row.original.art_itbm_venta)).toFixed(4),
      cotd_precio: values.cotd_precio,
      cotd_total: ((Number(values.cotd_cantidad)*Number(values.cotd_precio))*(1+Number(row.original.art_itbm_venta))).toFixed(4),
    };
    setOBJCotizacionDetalle([...OBJCotizacionDetalle]);
    exitEditingMode();
  };

  const handleSaveRowCliente = ({exitEditingMode,row,values}) => {
    onClickUpdateClient({
      cli_id:row.original.cli_id, 
      cli_nombre:values.cli_nombre, 
      cli_direccion:row.original.cli_direccion,
      cli_telefono2:row.original.cli_telefono2,
      cli_ruc:values.cli_ruc, 
      cli_dv:values.cli_dv, 
      cli_telefono:values.cli_telefono, 
      cli_email: values.cli_email,
    })
    exitEditingMode();
  };

  OBJCotizacionDetalle.map((row,i)=>{
    subtotal = Number(subtotal) + Number((Number(row.cotd_cantidad)*Number(row.cotd_precio)))
    itbms = Number(itbms) +roundNumber(Number(row.cotd_itbms),3)
    total = Number(subtotal) + Number(itbms)
  });



  subtotal = roundNumber(subtotal,2);
  itbms = roundNumber(itbms,2);
  total = roundNumber(total,2);
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="fullscreen" className="modal-full-large">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Cotización</MDBModalTitle>
              {isTabClientes || isTabItems?"":<MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);fetch_cot_cotizacion()}}></MDBBtn>}
              
            </MDBModalHeader>
            <MDBModalBody>
              {isTabClientes?<>
                <Row>
                  <Col xs={12}>
                    <Label>Lista de cliente</Label>
                  </Col>
                  <Col xs={12} className="mb-2 datatable-factura">
                    {dataCliente !==undefined?<DATATABLE_LISTA_CLIENTE dataCliente ={dataCliente} 
                    selectCliente ={selectCliente} setTabClientes ={setTabClientes} handleSaveRow={handleSaveRowCliente}/>:<Label>Cargando...</Label>}
                  </Col>
                  <Col xs={10} >
                  </Col>
                  <Col xs={2} className="text-end">
                    <MDBBtn color="danger" className="d-flex cot-btn-save82"onClick={() => {setTabClientes(false);setFormClientesBlank()}}>
                    <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />  Cerrar
                    </MDBBtn>
                  </Col>
                </Row>
              </>:
              isTabAddClientes?<>
              <Col xs={12}>
              <Label>Nuevo cliente</Label>
              </Col>
              <Col xs={12}>
              <form>
                <MDBInput
                  id="cli_nombre"
                  wrapperClass="mb-4"
                  label="* Nombre"
                  name="cli_nombre"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_nombre}
                />
                <MDBInput
                  id="cli_direccion"
                  wrapperClass="mb-4"
                  label="Dirección"
                  name="cli_direccion"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_direccion}
                />
                <MDBInput
                  type="text"
                  id="cli_telefono"
                  wrapperClass="mb-4"
                  label="Teléfono"
                  name="cli_telefono"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_telefono}
                />
                <MDBInput
                  id="cli_telefono2"
                  wrapperClass="mb-4"
                  label="Teléfono 2"
                  name="cli_telefono2"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_telefono2}
                />
                <MDBInput
                  type="text"
                  id="cli_ruc"
                  wrapperClass="mb-4"
                  label="RUC"
                  name="cli_ruc"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_ruc}
                />
                <MDBInput
                  type="number"
                  min={1}
                  max={99}
                  id="cli_dv"
                  wrapperClass="mb-4"
                  label="DV"
                  name="cli_dv"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_dv}
                />
                <MDBInput
                  type="email"
                  id="cli_email"
                  wrapperClass="mb-4"
                  label="E-MAIL"
                  name="cli_email"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_email}
                />
                 <MDBInput
                  type="cli_limitc"
                  id="cli_limitc"
                  wrapperClass="mb-4"
                  label="Monto de crédito"
                  name="cli_limitc"
                  onChange={onChangeCliente}
                  defaultValue={formClientes.cli_limitc}
                />
                <div className="form-check form-check-inline mt-1">
                <input className="form-check-input" type="checkbox" name="cli_credito" id="inlineRadioPrecio"
                onChange={handleOnChangeCheckBox}  value={formClientes.cli_credito} checked={formClientes.cli_credito==="1"?true:false}/>
                <label className="form-check-label" htmlFor="inlineRadioPrecio">Límite de crédito</label>
              </div>
              </form>
              <Row>
                <Col xs={12} className="text-end">
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                </Col>
                <Col xs={8}>
                 </Col>
                <Col xs={2} className="float-right">
                <MDBBtn color="danger" className="d-flex float-end cot-btn-save82"  onClick={() => {setTabAddClientes(false);setFormClientesBlank(); setError("")}}>
                    <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />      Cerrar
                  </MDBBtn>
                </Col>
                <Col  xs={2} className="float-right">
                <MDBBtn  color="primary" className="d-flex float-end"  onClick={() => {onClickSaveCliente();}}>
                  <MDBIcon  fas icon="save" className="me-1 class-btn-factura d-flex"  />   Guardar
                  </MDBBtn>
                </Col>
              </Row>
             
              
              </Col>
              </>:<>
              <>
              {isTabItems?<>
                <Row>
                    <Col xs={12} className="datatable-factura mb-2 datatable-factura">
                    <Label>Articulos</Label>
                      {dataItems !==undefined?<DATATABLE_LISTA_ITEMS dataItems ={dataItems} selectItem ={selectItem} setTabItems={setTabItems}/>:<Label>Cargando...</Label>}
                  </Col>
                  <Col xs={10}></Col>
                  <Col xs={2} className="text-end">
                  <MDBBtn color="danger" className="d-flex cot-btn-save82"  onClick={() => {setTabItems(false);}}>
                  <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />  Cerrar
              </MDBBtn>
                  </Col>
                </Row>
              </>:<>
              <form>
                <Row>
                  <Col xs={4} >
                    <Select
                      options={propsDropdown}
                      onChange={onChangeDropdown}
                      placeholder={formDropDownCliente.cli_nombre.length>0?formDropDownCliente.cli_nombre+" "+formDropDownCliente.cli_ruc:"Cliente"}
                      maxMenuHeight={150}
                    />
                  </Col>
                  <Col  xs={1}>
                    <img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setTabClientes(true)}}/>
                    <img src={ADD_IMAGE} className="src-image ml-1" alt="..." onClick={()=>{setTabAddClientes(true);setFormClientes(STATES_COT_COTIZACION({staneName:"formClientes"}))}}/>
                  </Col>
                  <Col xs={2}>
                    <MDBInput
                      id="dc_fecha"
                      type="date"
                      wrapperClass="mb-2"
                      name="cot_fecha"
                      onChange={onChange}
                      defaultValue={formCotizacion.cot_fecha}
                    />
                  </Col>
                  <Col xs={5}>
                  <MDBTextArea  
                   id="cot_descripcion"
                      wrapperClass="mb-2"
                      label="Descripción"
                      name="cot_descripcion"
                      onChange={onChange}
                      type="textarea"
                      rows="2"
                      defaultValue={formCotizacion.cot_descripcion}>

                  </MDBTextArea>
                  </Col>
                </Row>
              </form>
              <Row >
              <Col xs={6}>
                    <Label className="error-label-inventario">
                      - {error_upc}
                    </Label>
                  </Col>
              <Col xs={6} className="text-end">
 
                  {
              isTabClientes ===false && isTabAddClientes === false && isTabItems===false ?<><>
      
                <Col xs={12} className="text-center">
                <Row>
                {basicModalEdit?<Col xs={3}></Col>:null}
                <Col xs={6} className="text-end">
                {
                  basicModalEdit?<MDBBtn className="d-flex text-end"  onClick={() => ( toggleSaveEncabezado())}>
                  <MDBIcon fas icon="edit" className="me-1 class-btn-factura d-flex"  /> Actualizar encabezado
                </MDBBtn>:null
                }
                  <Label className="error-label">{error.length > 0 ? error : ""}</Label>
                  {isSaveI ? <Label className="success-label" >Guardado</Label> : ""}
                </Col>
                <Col xs={3} className="text-end">
                <MDBBtn color="danger"  className={basicModalEdit?"cot-btn-save25 d-flex":"cot-btn-cerrar d-flex"} onClick={() => {toggle(false);fetch_cot_cotizacion();}}>
                <MDBIcon fas icon="ban" className="me-1 class-btn-factura d-flex"  />    Cerrar
              </MDBBtn>
                </Col>
                <Col xs={3} className="text-end">
                
              {basicModalEdit?"":              <MDBBtn className="d-flex cot-btn-save" onClick={() => ( toggleSave())} disabled={OBJCotizacionDetalle[0]!==undefined?false:true}>
              <MDBIcon  fas icon="save" className="me-1 class-btn-factura d-flex"  />   Guardar
              </MDBBtn>}
                </Col>
                </Row>
                </Col>
              </>
              </>:null}
                  </Col>
                  <Col xs ={12} className="mt-2">
                    <Row>
                      <Col xs={2} className="d-flex">
                        <MDBInput
                          id="art_upc"
                          wrapperClass="mb-2"
                          label="Código"
                          name="art_upc"
                          onChange={onChangeCot_detail}
                          value={formCotCotizacionDetalle.art_upc}
                          disabled={enableTab}
                          onKeyDown={(e) =>{
                            handleKeyPress(e, formCotCotizacionDetalle.art_upc);
                          }
                          }
                        />
                        {formDropDownCliente.cli_nombre.length>0?<img src={SEARCH_IMAGE} className="src-image" alt="..." onClick={()=>{setTabItems(true)}}/>:null}
                      </Col>
                      <Col xs={6}>
                      <MDBInput
                          id="art_descripcion"
                          wrapperClass="mb-2"
                          label="Descripción del artículo"
                          name="art_descripcion"
                          onChange={onChangeCot_detail}
                          value={formCotCotizacionDetalle.art_descripcion}
                          disabled ={formCotCotizacionDetalle.art_upc.length>0 && usr_precio==="1"?false:true}
                        />
                      </Col>
                      <Col xs={2} className="d-flex">
                      <MDBInput
                          id="cotd_precio"
                          wrapperClass="mb-2"
                          label="Precio"
                          name="cotd_precio"
                          type={"number"}
                          min={"1"}
                          autoFocus ={true}
                          value={formCotCotizacionDetalle.cotd_precio}
                          disabled ={formCotCotizacionDetalle.art_upc.length>0 && usr_precio==="1"?false:true}
                          onChange={onChangeCot_detail}
                        />
                          <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            disabled ={formCotCotizacionDetalle.art_upc.length>0  ?false:true}
                          >
                          <img src={PRECIO_IMAGE} className="src-image style-precio-btn" alt="..."/>
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                              'aria-labelledby': 'basic-button',
                            }}
                          >
        <MenuItem onClick={()=>{setPrecio(formPrecio.art_precio);    setAnchorEl(false)}}>{formPrecio.art_precio}</MenuItem>
        <MenuItem onClick={()=>{setPrecio(formPrecio.art_precio2);    setAnchorEl(false)}}>{formPrecio.art_precio2}</MenuItem>
        <MenuItem onClick={()=>{setPrecio(formPrecio.art_precio3);    setAnchorEl(false)}}>{formPrecio.art_precio3}</MenuItem>
                          </Menu>
                      </Col>
                      <Col xs={2}>
                      <MDBInput
                          id="cotd_cantidad"
                          wrapperClass="mb-2"
                          label="Cantidad"
                          name="cotd_cantidad"
                          type={"number"}
                          min={"1"}
                          ref={inputCantidad}
                          // autoFocus ={true}
                          // disabled ={formCotCotizacionDetalle.art_upc.length>0?false:true}
                          onChange={onChangeCot_detail}
                          value={formCotCotizacionDetalle.cotd_cantidad}
                          onKeyDown={(e) =>
                            handleKeyPress(e, formCotCotizacionDetalle.cotd_cantidad)
                          }
                        />
                      </Col>
                      <Col className="modal-add-inventario datatable-factura">
                      <DATATABLE_COTIZACION_DETALLE enableTab={enableTab} selectItem ={selectItem} deleteRow={deleteRow} OBJCotizacionDetalle ={OBJCotizacionDetalle}
                      handleSaveRow={handleSaveRow} usr_precio={usr_precio}/>
                  </Col>
              </Row>
                  </Col>
                </Row>
              </>}
              </>
              </>
              }
              {isTabClientes ===false && isTabAddClientes === false && isTabItems===false ?<>              <Row className="mt-3">
                <FOOTER_FACTURA enableTab = {enableTab} subtotal = {subtotal} itbms = {itbms} total ={total}/>
                </Row></>:null}

            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const EnviadoCocinaModal = ({
  isOpen,
  toggle
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Enviando a la cocina..</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
            <img src={SUCCES_IMAGE} className="src-image-enviado" alt="..."/>
            <Row>
              <Col xs={12}>
              </Col>
            </Row>
            </MDBModalBody>
    
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};
const EnviadoModal = ({
  isOpen,
  toggle,
  dataListpago,
  total
}) => {
  var monto =0;
  dataListpago.map((data,i)=>{
    monto = monto + Number(data.list_monto)
  });
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Factura creada</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
            <img src={SUCCES_IMAGE} className="src-image-enviado" alt="..."/>
            <Row>
              <Col xs={3}>
              </Col>
              <Col xs={6} className="mt-3">
                    <MDBInput
                      className="input-iventario-totales2"
                      id="total"
                      wrapperClass="mb-4"
                      label="TOTAL"
                      name="total"
                      autoFocus={true}
                      value={total}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={3}>
                 </Col>
                <Col xs={3}>
                </Col>
                  <Col xs={6}>
                    <MDBInput
                      className="input-iventario-totales2"
                      id="recibido"
                      wrapperClass="mb-4"
                      label="RECIBIDO"
                      name="recibido"
                      autoFocus={true}
                      value={monto.toFixed(2)}
                      disabled={true}
                    />
                  </Col>
                  <Col xs={3}>
                  </Col>
                  <Col xs={3}>
                  </Col>
                  <Col xs={6}>
                    <MDBInput
                      className="input-iventario-totales2"
                      id="vuelto"
                      wrapperClass="mb-4"
                      label="VUELTO"
                      name="vuelto"
                      autoFocus={true}
                      value={(monto- total).toFixed(2)}
                      disabled={true}
                    />
              </Col>
              <Col xs={3}>

              </Col>
            </Row>
            </MDBModalBody>
    
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const ImageTooltipModal = ({
  isOpen,
  toggle,
  srcImage,
  ImgName
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1"   onClose={toggle}>
        <MDBModalDialog onClose={toggle}>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>{ImgName}</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody className="text-center">
              <img src={srcImage} className="src-image-articulo-tooltip" alt="..."/>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const AnulacionModal = ({
  dataListAnulacion,
  isOpen,
  toggle,
  formAnulacion,
  onChange,
  setFormAnulacion,
  toggleSave,
  errorAnulacion
}) => {
  const [istabAnular, setTabAnular] = useState(true);
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Anular factura</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => toggle(false)}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <Row>
                {istabAnular? <Col xs={12} className="datatable-factura">
                <DATATABLE_COLUMNS_LISTA_ANULACION dataListAnulacion ={dataListAnulacion} setTabAnular={setTabAnular}
                setFormAnulacion ={setFormAnulacion}/>
                </Col>:
                <React.Fragment>
                  <Col xs={4}>
                  <MDBInput
                  className="input-iventario-totales"
                  id="docNumber"
                  wrapperClass="mb-4"
                  label="# de factura"
                  name="docNumber"
                  autoFocus={true}
                  value={formAnulacion.fac_id.toString().padStart(10, "0")}
                  />
                  </Col>
                <Col xs={8}>
                  <MDBInput
                  className="input-iventario-totales"
                  id="motivo_anulacion"
                  wrapperClass="mb-4"
                  label="Motivo de la Anulacion"
                  name="motivo_anulacion"
                  autoFocus={true}
                  onChange={onChange}
                  value={formAnulacion.motivo_anulacion}
                  />
                  </Col>
                </React.Fragment>
}
              </Row>
            </MDBModalBody>
            <MDBModalFooter>
                {/* <Col xs={12} className="text-center"> */}
                  <Label className="error-label">{errorAnulacion.length > 0 ? errorAnulacion : ""}</Label>
                {/* </Col> */}
                <MDBBtn  onClick={() => toggle(false)} color="danger">
                    Cerrar
                </MDBBtn>
                <MDBBtn onClick={() =>  toggleSave({tipo:formAnulacion.fac_recibo})} disabled={formAnulacion.fac_id.length>0?false:true}>
                    Anular
                </MDBBtn>
                {/* <MDBBtn onClick={() =>  toggleSave({tipo:"1"})} disabled={formAnulacion.fac_id.length>0?false:true}>
                    Anular FE
                </MDBBtn> */}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

const DateRangeModal = ({
  isOpen,
  toggle,
  open,
  handleOnChangeDate,
  state
}) => {
  return (
    <React.Fragment>
      <MDBModal show={isOpen} setShow={toggle} tabIndex="-1" staticBackdrop>
        <MDBModalDialog >
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Calendario</MDBModalTitle>
              <MDBBtn className="btn-close" color="none" onClick={() => {toggle(false);}}></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
            <Row>
              <Col xs={12} className="text-center" >
                {open && (
                  <DateRange
                    onChange={handleOnChangeDate}
                    showSelectionPreview={false}
                    moveRangeOnFirstSelection={false}
                    editableDateInputs={true}
                    dateDisplayFormat="yyyy-MM-dd-"
                    ranges={state}
                    direction="horizontal"
                    className="box-calendar"
                  />
                )}
              </Col>
            </Row>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </React.Fragment>
  );
};

export {
  EnviadoCocinaModal,
  DateRangeModal,
  EnviadoModal,
  PagarModal,
  DeleteModal,
  UsuariosModal,
  BodegaModal,
  ClienteModal,
  ProveedorModal,
  CategoriaModal,
  MarcaModal,
  ArticuloModal,
  InventarioModal,
  ListaClienteModal,
  ListaItemModal,
  ListaFacModal,
  CotizacionModal,
  AnulacionModal,
  ApartadoModal,
  RecuentoModal,
  ListaCotizacion,
  ListaOrden,
  ImageTooltipModal,
  ListaCreditos
};
