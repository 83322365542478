import React, { useState, useEffect, useContext } from "react";
import { res, validate } from 'react-email-validator';
import moment from "moment";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "./../Componentes/www/style.css";
import {DATATABLE_CATEGORIAS,DATATABLE_USUARIOS,DATATABLE_MARCAS,DATATABLE_BODEGAS,DATATABLE_CLIENTES} from "../Componentes/Datatables";
import {FIND_DESC} from "./../Componentes/Util";
import {
  BodegaModal,
  ClienteModal,
  CategoriaModal,
  MarcaModal,
  UsuariosModal,
  DeleteModal
} from "./../Componentes/Modal";
import StoreContext from "./../Componentes/Store/Context";
import {
  MDBIcon,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBTextArea 
} from "mdb-react-ui-kit";

import {
  get_compania,
  put_compania,
  get_bod_bodega,
  post_bod_bodega,
  put_bod_bodega_sts,
  put_bod_bodega,
  get_cli_cliente,
  post_cli_cliente,
  put_cli_cliente,
  put_cli_cliente_sts,
  get_cat_categoria,
  post_cat_categoria,
  put_cat_categoria,
  put_cat_categoria_sts,
  get_mar_marca,
  post_mar_marca,
  put_mar_marca,
  put_mar_marca_sts,
  get_usr_usuario,
  post_usr_usuario,
  put_usr_usuario,
  put_usr_usuario_sts,
  put_res_restaurante,
  put_res_restaurante_sts,
  post_res_restaurante,
  get_res_restaurante
} from "../Componentes/Services";
import { Container, Label, Row, Col } from "reactstrap";
import { error } from "jquery";
export const Configuracion = () => {
  const [iconsActive, setIconsActive] = useState("tab1");

  const handleIconsClick = (value) => {
    if (value === iconsActive) {
      return;
    }
    setIconsActive(value);
  };

  return (
    <>
      <MDBTabs className="mb-3">
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab1")} active={iconsActive === "tab1"}>
            <MDBIcon fas icon="building" className="me-2" /> Compañia
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab2")} active={iconsActive === "tab2"}>
            <MDBIcon fas icon="warehouse" className="me-2" /> Bodegas
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab3")} active={iconsActive === "tab3"}>
            <MDBIcon fas icon="people-carry" className="me-2" /> Categorias
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab4")} active={iconsActive === "tab4"}>
            <MDBIcon fas icon="code-branch" className="me-2" /> Marcas
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab5")} active={iconsActive === "tab5"}>
            <MDBIcon fas icon="file-invoice" className="me-2" /> Usuarios
          </MDBTabsLink>
        </MDBTabsItem>
        <MDBTabsItem>
          <MDBTabsLink onClick={() => handleIconsClick("tab6")} active={iconsActive === "tab6"}>
            <MDBIcon fas icon="utensils" className="me-2" /> Restaurante
          </MDBTabsLink>
        </MDBTabsItem>
      </MDBTabs>

      <MDBTabsContent>
        <MDBTabsPane show={iconsActive === "tab1"}>
          <Compania />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab2"}>
          <Bodegas />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab3"}>
          <Categorias />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab4"}>
          <Marcas />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab5"}>
          <Usuarios />
        </MDBTabsPane>
        <MDBTabsPane show={iconsActive === "tab6"}>
          <Restaurante />
        </MDBTabsPane>
      </MDBTabsContent>
    </>
  );
};
function Compania() {
  const [error, setError] = useState("");
  const [dataCompania, setDataCompania] = useState({
    bod_suc: "",
    com_nombre: "",
    com_ruc: "",
    com_dv: "",
    com_direccion: "",
    com_telefono: "",
    com_telefono2: "",
    com_email: "",
    com_fax: "",
    com_web: "",
    com_info_interes:"",
    com_cotizacion_info:""
  });
  const onClickUpdateCompania = () => {
    var body = {
      com_nombre: dataCompania.com_nombre,
      com_ruc: dataCompania.com_ruc,
      com_dv: dataCompania.com_dv,
      com_direccion: dataCompania.com_direccion,
      com_telefono: dataCompania.com_telefono,
      com_telefono2: dataCompania.com_telefono2,
      com_email: dataCompania.com_email,
      com_fax: dataCompania.com_fax,
      com_web: dataCompania.com_web,
      com_info_interes:dataCompania.com_info_interes,
      com_cotizacion_info:dataCompania.com_cotizacion_info
    };

    var result = put_compania(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchCompania();
      else
        setError("ERROR "+data);
    })
  };

  async function fetchCompania() {
    var response;
    response = await get_compania();
    var data = response[0];
    setDataCompania({
      bod_suc: data.bod_suc,
      com_nombre: data.com_nombre,
      com_ruc: data.com_ruc,
      com_dv: data.com_dv,
      com_direccion: data.com_direccion,
      com_telefono: data.com_telefono,
      com_telefono2: data.com_telefono2,
      com_email: data.com_email,
      com_fax: data.com_fax,
      com_web: data.com_web,
      com_info_interes:data.com_info_interes,
      com_cotizacion_info:data.com_cotizacion_info
    });
  }

  function onChange(event) {
    const { value, name } = event.target;
    setDataCompania({
      ...dataCompania,
      [name]: value,
    });
  }

  useEffect(() => {
    fetchCompania();
  }, []);
  var ar =[
    {id:"1"},
    {id:"2"},
  ]

  return (
    <Container>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_nombre"
            name="com_nombre"
            label="Nombre"
            onChange={onChange}
            value={dataCompania.com_nombre}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_ruc"
            name="com_ruc"
            label="RUC"
            onChange={onChange}
            value={dataCompania.com_ruc}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_dv"
            name="com_dv"
            label="DV"
            onChange={onChange}
            value={dataCompania.com_dv}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_web"
            name="com_web"
            label="Pagina Web"
            onChange={onChange}
            value={dataCompania.com_web}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_direccion"
            name="com_direccion"
            label="Dirección"
            onChange={onChange}
            value={dataCompania.com_direccion}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_telefono"
            name="com_telefono"
            label="Telefono"
            onChange={onChange}
            value={dataCompania.com_telefono}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_telefono2"
            name="com_telefono2"
            label="Telefono 2"
            onChange={onChange}
            value={dataCompania.com_telefono2}
          />
        </MDBCol>
        <MDBCol>
          <MDBInput
            id="com_fax"
            name="com_fax"
            label="FAX"
            onChange={onChange}
            value={dataCompania.com_fax}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBInput
            id="com_email"
            name="com_email"
            label="EMAIL"
            onChange={onChange}
            value={dataCompania.com_email}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTextArea 
            id="com_info_interes"
            name="com_info_interes"
            label="Informacion de interes en la factura"
            rows={8}
            onChange={onChange}
            value={dataCompania.com_info_interes}
          />
        </MDBCol>
      </MDBRow>
      <MDBRow className="mb-4">
        <MDBCol>
          <MDBTextArea 
            id="com_cotizacion_info"
            name="com_cotizacion_info"
            label="Informacion de interes en la cotizaciones"
            rows={8}
            onChange={onChange}
            value={dataCompania.com_cotizacion_info}
          />
        </MDBCol>
      </MDBRow>
      <Row>
        <Col xs={12}>
        <Label className="error-label">{error.length > 0 ? error : ""}</Label>
        </Col>
        <Col xs={5}></Col>
        <Col xs={2}>
          <MDBBtn className="mb-4" type="submit" block onClick={() => onClickUpdateCompania()}>
          <MDBIcon fas icon="save" className="me-1" /> Guardar
          </MDBBtn>
        </Col>
        <Col xs={5}></Col>
      </Row>
    </Container>
  );
}
function Bodegas() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [error, setError] = useState("");
  const { usr_sucursal } = useContext(StoreContext);
  const [dataBodegas, setDataBodegas] = useState([]);
  const [formBodegas, setFormBodegas] = useState({
    bod_id: "",
    bod_nombre: "",
    bod_ubicacion: "",
    bod_sts: "",
  });

  const onClickUpdateBodega = () => {
    var body = {
      bod_id: formBodegas.bod_id,
      bod_suc: formBodegas.bod_suc,
      bod_nombre: formBodegas.bod_nombre,
      bod_ubicacion: formBodegas.bod_ubicacion,
    };
    var result = put_bod_bodega(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchBodega();
      else
        setError("ERROR "+data);
    })
  };

  const onClickUpdateBodega_sts = () => {
    var body = {
      bod_id: formBodegas.bod_id,
      bod_sts: 0,
    };

    var result = put_bod_bodega_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchBodega();
      else
        setError("ERROR "+data);
    })
  };

  const onClickSaveBodega = () => {
    var body = {
      bod_suc: usr_sucursal,
      bod_nombre: formBodegas.bod_nombre,
      bod_ubicacion: formBodegas.bod_ubicacion,
    };
    if (formBodegas.bod_nombre.length > 0) {
      var result = post_bod_bodega(body);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchBodega();
        else
          setError("ERROR "+data);
      })
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchBodega() {
    var response;
    response = await get_bod_bodega();
    setDataBodegas(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormBodegas({
      ...formBodegas,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchBodega();
  }, []);

  return (
    <Container>
      {basicModal && (
        <BodegaModal
          props={formBodegas}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveBodega}
          toggleSaveEdit={onClickUpdateBodega}
          onChange={onChange}
          error={error}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formBodegas.bod_nombre}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateBodega_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_BODEGAS dataBodegas={dataBodegas} setBasicModalDelete={setBasicModalDelete} setFormBodegas={setFormBodegas} 
        setBasicModal={setBasicModal} setBasicModalEdit={setBasicModalEdit} setError={setError} fetchBodega={fetchBodega}/>
        </Col>
      </Row>
    </Container>
  );
}
function Categorias() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingCa, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataCategorias, setDataCategorias] = useState([]);
  const [formCategorias, setFormCategorias] = useState({
    cat_id: "",
    cat_descripcion: "",
    cat_sts: "",
  });

  const onClickUpdateCategorias = () => {
    var body = {
      cat_id: formCategorias.cat_id,
      cat_descripcion: formCategorias.cat_descripcion,
    };

    var result = put_cat_categoria(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchCategoria();
      else
        setError("ERROR "+data);
    });
  };

  const onClickUpdateCategorias_sts = () => {
    var body = {
      cat_id: formCategorias.cat_id,
      cat_sts: 0,
    };

    var result = put_cat_categoria_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchCategoria();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveCategorias = () => {
    var body = {
      cat_descripcion: formCategorias.cat_descripcion,
    };
    if (formCategorias.cat_descripcion.length > 0) {
      post_cat_categoria(body);
      var result = post_cat_categoria(body);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchCategoria();
        else
          setError("ERROR "+data);
      });
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchCategoria() {
    setLoading(true);
    var response;
    response = await get_cat_categoria();
    setDataCategorias(response);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setBasicModal(false);
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormCategorias({
      ...formCategorias,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchCategoria();
  }, []);

  return (
    <Container>
      {basicModal && (
        <CategoriaModal
          props={formCategorias}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveCategorias}
          toggleSaveEdit={onClickUpdateCategorias}
          onChange={onChange}
          error={error}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formCategorias.cat_descripcion}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateCategorias_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_CATEGORIAS
        dataCategorias={dataCategorias} 
        setBasicModalDelete={setBasicModalDelete} 
        setFormCategorias={setFormCategorias} 
        setBasicModal={setBasicModal} setBasicModalEdit={setBasicModalEdit}
        setError={setError} fetchCategoria={fetchCategoria}isLoadingCa={isLoadingCa}
        />
        </Col>
      </Row>
    </Container>
  );
}
function Marcas() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [isLoadingM, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataMarcas, setDataMarcas] = useState([]);
  const [formMarcas, setFormMarcas] = useState({
    mar_id: "",
    mar_descripcion: "",
    mar_sts: "",
  });

  const onClickUpdateMarcas = () => {
    var body = {
      mar_id: formMarcas.mar_id,
      mar_descripcion: formMarcas.mar_descripcion,
    };

    var result = put_mar_marca(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchMarcas();
      else
        setError("ERROR "+data);
    });
  };

  const onClickUpdateMarcas_sts = () => {
    var body = {
      mar_id: formMarcas.mar_id,
      mar_sts: 0,
    };

    put_mar_marca_sts(body);
    var result = put_mar_marca_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchMarcas();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveMarcas = () => {
    var body = {
      mar_descripcion: formMarcas.mar_descripcion,
    };
    if (formMarcas.mar_descripcion.length > 0) {
      var result = post_mar_marca(body);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchMarcas();
        else
          setError("ERROR "+data);
      });
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchMarcas() {
    setLoading(true);
    var response;
    response = await get_mar_marca();
    setDataMarcas(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormMarcas({
      ...formMarcas,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchMarcas();
  }, []);

  return (
    <Container>
      {basicModal && (
        <MarcaModal
          props={formMarcas}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveMarcas}
          toggleSaveEdit={onClickUpdateMarcas}
          onChange={onChange}
          error={error}
        />
      )}
            {basicModalDelete && (
        <DeleteModal
          props={formMarcas.mar_descripcion}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateMarcas_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
        <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_MARCAS dataMarcas ={dataMarcas} 
        setBasicModalDelete={setBasicModalDelete}
        setFormMarcas={setFormMarcas}
        setBasicModal={setBasicModal}
        setBasicModalEdit={setBasicModalEdit}
        setError={setError}
        fetchMarcas={fetchMarcas}
        isLoadingM={isLoadingM} />
        </Col>
      </Row>
    </Container>
  );
}
function Usuarios() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingU, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [dataUsuarios, setDataUsuarios] = useState([]);
  const [formUsuarios, setFormUsuarios] = useState({
    usr_id: "",
    usr_usuario: "",
    usr_clave: "",
    usr_nombre: "",
    usr_perfil: "",
    usr_sucursal: "",
    usr_print:"",
    usr_ajustes:"",
    usr_factura:"",
    usr_inventario:"",
    usr_cotizacion:"",
    usr_articulo:"",
    usr_reporte:"",
    usr_apartado:"",
    usr_clientes:"",
    usr_precio:""
  });

  const onClickUpdateUsuarios = () => {
    var result = put_usr_usuario(formUsuarios);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchUsuarios();
      else
        setError("ERROR "+data);
    });
  };

  const onClickUpdateUsuario_sts = () => {
    var body = {
      usr_id: formUsuarios.usr_id,
      usr_sts: 0,
    };
    var result = put_usr_usuario_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchUsuarios();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveUsuarios = () => {

    if (
      formUsuarios.usr_usuario.length > 0 &&
      formUsuarios.usr_clave.length > 0 &&
      formUsuarios.usr_nombre.length > 0 &&
      formUsuarios.usr_print.length >0
    ) {
      var result = post_usr_usuario(formUsuarios);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchUsuarios();
        else
          setError("ERROR "+data);
      });
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchUsuarios() {
    setLoading(true);
    var response;
    response = await get_usr_usuario();
    setDataUsuarios(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoading(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormUsuarios({
      ...formUsuarios,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchUsuarios();
  }, []);

  const onChangeDropdown = (selectedOptions) => {
    if(selectedOptions.objName==="print"){
      setFormUsuarios({
        ...formUsuarios,
        usr_print:selectedOptions.value
    })
    }
    else{
      setFormUsuarios({
        ...formUsuarios,
        usr_perfil:selectedOptions.value
    })
    }
    };

    const handleOnChangeCheckBox = (event) => {
      const { value, name } = event.target;
      setFormUsuarios({
        ...formUsuarios,
        [name]:value==="0"?"1":"0"
    })
    };
  return (
    <Container>
      {basicModal && (
        <UsuariosModal
          props={formUsuarios}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveUsuarios}
          toggleSaveEdit={onClickUpdateUsuarios}
          onChange={onChange}
          error={error}
          onChangeDropdown={onChangeDropdown}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
        />
      )}
      {basicModalDelete && (
        <DeleteModal
          props={formUsuarios.usr_nombre}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateUsuario_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
      <Col className="modal-add-inventario datatable-factura">
        <DATATABLE_USUARIOS dataUsuarios ={dataUsuarios} 
        setBasicModalDelete={setBasicModalDelete}
        setFormUsuarios={setFormUsuarios}
        setBasicModal={setBasicModal}
        setBasicModalEdit={setBasicModalEdit}
        setError={setError}
        fetchUsuarios={fetchUsuarios}
        isLoadingU={isLoadingU} />
        </Col>
      </Row>
    </Container>
  );
}

function Restaurante() {
  const [basicModal, setBasicModal] = useState(false);
  const [basicModalDelete, setBasicModalDelete] = useState(false);
  const [basicModalEdit, setBasicModalEdit] = useState(false);
  const [isLoadingRes, setLoadingRes] = useState(false);
  const [error, setError] = useState("");
  const [dataRestaurante, setDataRestaurante] = useState([]);
  const [formRestaurante, setFormRestaurante] = useState({
    res_id: "",
    res_nombre: "",
    res_sts: ""
  });

  const onClickUpdateRestaurante = () => {
    var body ={
      res_nombre:formRestaurante.res_nombre
    }
    var result = put_res_restaurante(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchRestaurante();
      else
        setError("ERROR "+data);
    });
  };

  const onClickUpdateRestaurante_sts = () => {
    var body = {
      res_id: formRestaurante.res_id,
      res_sts: 0,
    };
    var result = put_res_restaurante_sts(body);
    result.then((data) => {
      setError("");
      if(data===true)
        fetchRestaurante();
      else
        setError("ERROR "+data);
    });
  };

  const onClickSaveUsuarios = () => {
    if (formRestaurante.res_nombre.length > 0 ) {
      var result = post_res_restaurante(formRestaurante);
      result.then((data) => {
        setError("");
        if(data===true)
          fetchRestaurante();
        else
          setError("ERROR "+data);
      });
    } else {
      setError("Campos importantes estan vacios");
    }
  };

  async function fetchRestaurante() {
    setLoadingRes(true);
    var response;
    response = await get_res_restaurante();
    setDataRestaurante(response);
    setBasicModal(false);
    setBasicModalDelete(false);
    setBasicModalEdit(false)
    setLoadingRes(false);
    setError("");
  }

  function onChange(event) {
    const { value, name } = event.target;
    setFormRestaurante({
      ...formRestaurante,
      [name]: value,
    });
    setError("");
  }

  useEffect(() => {
    fetchRestaurante();
  }, []);

  const onChangeDropdown = () => {
    setFormRestaurante({
      ...formRestaurante,
      // usr_perfil:selectedOptions.value
  })
    // if(selectedOptions.objName==="print"){
    //   setFormUsuarios({
    //     ...formRestaurante,
    //     usr_print:selectedOptions.value
    // })
    // }
    // else{
    //   setFormRestaurante({
    //     ...formRestaurante,
    //     usr_perfil:selectedOptions.value
    // })
    // }
    };

    // const handleOnChangeCheckBox = (event) => {
    //   const { value, name } = event.target;
    //   setFormUsuarios({
    //     ...formUsuarios,
    //     [name]:value==="0"?"1":"0"
    // })
    // };
  return (
    <Container>
      {/* {basicModal && (
        <UsuariosModal
          props={formUsuarios}
          isOpen={basicModal}
          basicModalEdit={basicModalEdit}
          toggle={setBasicModal}
          toggleSave={onClickSaveUsuarios}
          toggleSaveEdit={onClickUpdateUsuarios}
          onChange={onChange}
          error={error}
          onChangeDropdown={onChangeDropdown}
          handleOnChangeCheckBox={handleOnChangeCheckBox}
        />
      )} */}
      {basicModalDelete && (
        <DeleteModal
          props={formRestaurante.res_nombre}
          isOpen={basicModalDelete}
          toggle={setBasicModalDelete}
          toggleDelete={onClickUpdateRestaurante_sts}
          text={"Eliminar este registro?"}
          error={error}
        />
      )}
      <Row>
      <Col xs={2}>
        <form>
          <MDBInput
            id="res_nombre"
            wrapperClass="mb-4"
            type="number"
            min={1}
            max={99}
            label="* Nombre"
            name="res_nombre"
            onChange={onChange}
            defaultValue={formRestaurante.res_nombre}
          />
        </form>
        {/* <DATATABLE_USUARIOS dataUsuarios ={dataUsuarios} 
        setBasicModalDelete={setBasicModalDelete}
        setFormUsuarios={setFormUsuarios}
        setBasicModal={setBasicModal}
        setBasicModalEdit={setBasicModalEdit}
        setError={setError}
        fetchUsuarios={fetchUsuarios}
        isLoadingU={isLoadingU} /> */}
        </Col>
        <Col xs={10}>
        </Col>
        <Col xs={12} className="text-center">
          <Label className="error-label">{error.length > 0 ? error : ""}</Label>
          <MDBBtn   onClick={() => onClickUpdateRestaurante()}>
          <MDBIcon fas icon="save" className="me-1" />   Guardar
          </MDBBtn>
        </Col>
      </Row>
    </Container>
  );
}