import moment from "moment";
import $ from 'jquery';

export const CODIGO_SUCURSAL_EMISOR="0001";
const IP ="http://localhost:8080";
// const IP ="http://192.168.100.25:8080"; // TANIA
// const IP ="http://192.168.1.150:8080";

// export const url = IP+"/sys_mbo/";
// export const urlFacturaElectronica = IP+"/sys_mbo/tax_sysm/";
// export const imgURLServer="C:/xampp/htdocs/sys_mbo/uploads/articulos/";
// export const imgURLocalImage = IP+"/sys_mbo/uploads/articulos/";
//HOST
export const url = "https://mtcsoftware.mtechnologypanama.com/sys_mbo/";
export const urlFacturaElectronica = "http://localhost:8080/sys_mbo/tax_sysm/";
export const imgURLServer="/home/u565547220/domains/mtechnologypanama.com/public_html/mtcsoftware/sys_mbo/uploads/articulos/";
export const imgURLocalImage="https://mtcsoftware.mtechnologypanama.com/sys_mbo/uploads/articulos/";
export const nonce="enc-base64";
export const path="login";
export const privateKey="MIICXAIBAAKBgQCqGKukO1De7zhZj6+H0qtjTkVxwTCpvKe4eCZ0FPqri0cb2JZfXJ";

export const dataTipo = [
  { value: '0', label: 'null',objName:"null" },
  { value: '1', label: 'Administrador',objName:"tipo" },
  { value: '2', label: 'Vendedor',objName:"tipo" }
]
export const monthname = [
  {name:"Enero"},
  {name:"Febrero"},
  {name:"Marzo"},
  {name:"Abril"},
  {name:"Mayo"},
  {name:"Junio"},
  {name:"Julio"},
  {name:"Agosto"},
  {name:"Septiembre"},
  {name:"Octubre"},
  {name:"Noviembre"},
  {name:"Diciembre"},
]
export const dataDropdownTipo = [
    { value: '1', label: 'Administrador',objName:"tipo" },
    { value: '2', label: 'Vendedor',objName:"tipo" },
    { value: '3', label: 'Tablet',objName:"tipo" },
    { value: '4', label: 'Restaurante factura',objName:"tipo" }
  ]

  export const dataDropdownPrint= [
    { value: '1', label: 'PDF',objName:"print" },
    { value: '2', label: 'Recibo termico',objName:"print" },
    { value: '3', label: 'Comprobante de compra',objName:"print" }
  ]

export async function put_compania(data) {
    const response = await fetch(url + "SettingController/put_compania", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function get_compania() {
    var urlFetch = url + "SettingController/get_compania"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_bod_bodega(data) {
    const response = await fetch(url + "BodegaController/post_bod_bodega", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function put_bod_bodega_sts(data) {
    const response = await fetch(url + "BodegaController/put_bod_bodega_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function put_bod_bodega(data) {
    const response = await fetch(url + "BodegaController/put_bod_bodega", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function get_bod_bodega() {
    var urlFetch = url + "BodegaController/get_bod_bodega"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_cli_cliente(data) {
    const response = await fetch(url + "ClienteController/post_cli_cliente", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function  get_caja_n() {
    var urlFetch = url + "SettingController/get_caja_n"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_cli_cliente_restaurant() {
    var urlFetch = url + "ClienteController/get_cli_cliente_restaurant"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cli_cliente() {
    var urlFetch = url + "ClienteController/get_cli_cliente"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function put_cli_cliente_sts(data) {
    const response = await fetch(url + "ClienteController/put_cli_cliente_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function put_res_restaurante_sts(data) {
    const response = await fetch(url + "RestauranteController/put_res_restaurante_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_res_restaurante(data) {
    const response = await fetch(url + "RestauranteController/put_res_restaurante", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_cli_cliente(data) {
    const response = await fetch(url + "ClienteController/put_cli_cliente", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_pro_proveedor(data) {
    const response = await fetch(url + "ProveedorController/post_pro_proveedor", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function  get_pro_proveedor() {
    var urlFetch = url + "ProveedorController/get_pro_proveedor"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function put_pro_proveedor_sts(data) {
    const response = await fetch(url + "ProveedorController/put_pro_proveedor_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_pro_proveedor(data) {
    const response = await fetch(url + "ProveedorController/put_pro_proveedor", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_cat_categoria(data) {
    const response = await fetch(url + "CategoriaController/post_cat_categoria", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_cat_categoria() {
    var urlFetch = url + "CategoriaController/get_cat_categoria"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function put_cat_categoria(data) {
    const response = await fetch(url + "CategoriaController/put_cat_categoria", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_cat_categoria_sts(data) {
    const response = await fetch(url + "CategoriaController/put_cat_categoria_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_mar_marca(data) {
    const response = await fetch(url + "MarcaController/post_mar_marca", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_mar_marca() {
    var urlFetch = url + "MarcaController/get_mar_marca"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function put_mar_marca(data) {
    const response = await fetch(url + "MarcaController/put_mar_marca", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_mar_marca_sts(data) {
    const response = await fetch(url + "MarcaController/put_mar_marca_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_res_restaurante(data) {
    const response = await fetch(url + "RestauranteController/post_res_restaurante", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function  get_ord_ordenes(ord_mesa) {
    var urlFetch = url + "OrdenController/get_ord_ordenes/"+ord_mesa+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_ord_ordenes_mesas() {
    var urlFetch = url + "OrdenController/get_ord_ordenes_mesas/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_res_restaurante() {
    var urlFetch = url + "RestauranteController/get_res_restaurante"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_usr_usuario(data) {
    const response = await fetch(url + "UsuarioController/post_usr_usuario", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function  get_ord_cocina() {
    var urlFetch = url + "OrdenController/get_ord_cocina"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_usr_usuario() {
    var urlFetch = url + "UsuarioController/get_usr_usuario"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function put_orden_detalle(data) {
    const response = await fetch(url + "OrdenController/put_orden_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_delete_orden_detalle(data) {
    const response = await fetch(url + "OrdenController/put_delete_orden_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_sts_ordenes(data) {
    const response = await fetch(url + "OrdenController/put_sts_ordenes", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_usr_usuario(data) {
    const response = await fetch(url + "UsuarioController/put_usr_usuario", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_usr_usuario_sts(data) {
    const response = await fetch(url + "UsuarioController/put_usr_usuario_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_art_articulo(data) {
    const response = await fetch(url + "ArticuloController/post_art_articulo", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_art_articulo() {
    var urlFetch = url + "ArticuloController/get_art_articulo"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function put_art_articulo(data) {
    const response = await fetch(url + "ArticuloController/put_art_articulo", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_art_articulo_sts(data) {
    const response = await fetch(url + "ArticuloController/put_art_articulo_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function put_delete_img(body) {
    const response = await fetch(url + "ArticuloController/put_delete_img", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_artb_sts(body) {
    const response = await fetch(url + "ArticuloController/put_artb_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function  get_apartado() {
    var urlFetch = url + "ApartadoController/get_apartado"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_inventario() {
    var urlFetch = url + "CompraController/get_inventario"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function put_cot_cotizacion_sts(data) {
    const response = await fetch(url + "CotizacionController/put_cot_cotizacion_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_dc_compra_sts(data) {
    const response = await fetch(url + "CompraController/put_dc_compra_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_ap_apartado_sts(data) {
    const response = await fetch(url + "ApartadoController/put_ap_apartado_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_articulo_by_code(art_upc) {
    var urlFetch = url + "CompraController/get_articulo_by_code/"+art_upc+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_dc_compra(data) {
    const response = await fetch(url + "CompraController/post_dc_compra", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function post_ap_apartado(data) {
    const response = await fetch(url + "ApartadoController/post_ap_apartado", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function post_cot_cotizacion(data) {
    const response = await fetch(url + "CotizacionController/post_cot_cotizacion", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function post_artb_bodega(data) {
    const response = await fetch(url + "ArticuloController/post_artb_bodega", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_compra_detalle(dc_identificador) {
    var urlFetch = url + "CompraController/get_compra_detalle/"+dc_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_apartado_detalle(dc_identificador) {
    var urlFetch = url + "ApartadoController/get_apartado_detalle/"+dc_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_all_cotizacion() {
    var urlFetch = url + "CotizacionController/get_all_cotizacion/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cotd_cotizacion_detail(cotd_identificador) {
    var urlFetch = url + "CotizacionController/get_cotd_cotizacion_detail/"+cotd_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function put_dc_compra(data) {
    const response = await fetch(url + "CompraController/put_dc_compra", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function delete_ap_apartado_detalle(data) {
    const response = await fetch(url + "ApartadoController/delete_ap_apartado_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function delete_dc_compra_detalle(data) {
    const response = await fetch(url + "CompraController/delete_dc_compra_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function put_cotd_cotizacion_detalle_row(data) {
    const response = await fetch(url + "CotizacionController/put_cotd_cotizacion_detail_row", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_apd_apartado_detalle_row(data) {
    const response = await fetch(url + "ApartadoController/put_apd_apartado_detalle_row", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function put_apd_apartado_encabezado(data) {
    const response = await fetch(url + "ApartadoController/put_apd_apartado_encabezado", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_dcd_compra_detalle_row(data) {
    const response = await fetch(url + "CompraController/put_dcd_compra_detalle_row", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_ap_apartado_detalle_row(data) {
    const response = await fetch(url + "ApartadoController/post_ap_apartado_detalle_row,", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_dc_compra_detalle_row(data) {
    const response = await fetch(url + "CompraController/post_dc_compra_detalle_row", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_dcd_compra_encabezado(data) {
    const response = await fetch(url + "CompraController/put_dcd_compra_encabezado", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function processInventario(data) {
    const response = await fetch(url + "CompraController/processInventario", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_fcd_factura_detalle_row(data) {
    const response = await fetch(url + "FacturaController/put_fcd_factura_detalle_row", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_factura_detalle(fac_identificador) {
    var urlFetch = url + "FacturaController/get_factura_detalle/"+fac_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function put_fac_factura_encabezado(data) {
    const response = await fetch(url + "FacturaController/put_fac_factura_encabezado", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_factura(fac_id) {
    var urlFetch = url + "FacturaController/get_factura"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_fac_factura_detalle_row(data) {
    const response = await fetch(url + "FacturaController/post_fac_factura_detalle_row", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function delete_fcd_factura_detalle(data) {
    const response = await fetch(url + "FacturaController/delete_fcd_factura_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_ord_orden(data) {
    const response = await fetch(url + "OrdenController/post_ord_orden", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_fac_factura(data) {
    const response = await fetch(url + "FacturaController/post_fac_factura", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_frm_pago() {
    var urlFetch = url + "FormaPagoController/get_frm_pago"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_itbms() {
    var urlFetch = url + "SettingController/get_itbms"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export  async function post_AnulacionElectronica(data) {
    $.ajax({
      url: "http://localhost:8080/sys_mbo/tax_sysm/" + "fac.php",
      crossDomain: true,
      mode: "cors",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, HEAD, OPTIONS"
      },
      data:JSON.stringify(data),
      type: 'POST',
      success: function(response){
        if(data.print_type==="1"){
          window.open("http://localhost:8080/sys_mbo/tax_sysm/pdf/"+data.fac_docn+".pdf");
        }
        if(response===1){
        }else{

        }
      }
  }); 
    return true;
  }
  export  async function post_facturaElectronica(data) {
    $.ajax({
      url: "http://localhost:8080/sys_mbo/tax_sysm/" + "fac.php",
      crossDomain: true,
      mode: "cors",
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': "GET, PUT, POST, DELETE, HEAD, OPTIONS"
      },
      data:JSON.stringify(data),
      type: 'POST',
      success: function(response){
        if(data.print_type==="1"){
          window.open("http://localhost:8080/sys_mbo/tax_sysm/pdf/"+data.fac_docn+".pdf");
        }
          if(response===1){
          }else{
    
          }
      }
  }); 
    return true;
  }

  export async function  get_artb_bodega_by_articulo(artb_articulo) {
    var urlFetch = url + "ArticuloController/get_artb_bodega_by_articulo/"+artb_articulo+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_list_items_restaurante() {
    var urlFetch = url + "ArticuloController/get_list_items_restaurante/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_list_items() {
    var urlFetch = url + "ArticuloController/get_list_items/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_cotizacion() {
    var urlFetch = url + "CotizacionController/get_cotizacion/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function post_cotd_cotizacion_detalle_row(data) {
    const response = await fetch(url + "CotizacionController/post_cotd_cotizacion_detalle_row", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function postAnular(data) {
    const response = await fetch(url + "FacturaController/postAnular", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function  get_report_x({fac_fecha,fac_caja,fac_vendedor}) {
    var urlFetch = url + "ReportsController/get_report_x/"+fac_fecha+"/"+fac_caja+"/"+fac_vendedor+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_report_ventas_global(fac_fecha) {
    var urlFetch = url + "ReportsController/get_cotizacion/"+fac_fecha+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_list_fac_imprimir() {
    console.log()
    var urlFetch = url + "FacturaController/get_list_fac_imprimir/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_list_itemsFactura(fcd_identificador) {
    var urlFetch = url + "ArticuloController/get_list_itemsFactura/"+fcd_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_frm_pago_imprimir_fac(fcd_identificador) {
    var urlFetch = url + "FormaPagoController/get_frm_pago_imprimir_fac/"+fcd_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  
  export async function  get_list_anulacion(fac_fecha) {
    var urlFetch = url + "FacturaController/get_list_anulacion/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_report_x_global(startDate,endDate) {
  
    var urlFetch = url + "ReportsController/get_report_x_global/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_report_venta(startDate,endDate) {
  
    var urlFetch = url + "ReportsController/get_report_ventas_global/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export  async function print_report_x(data) {
    $.ajax({
      // url: 'http://localhost/sys_mbo/tax_sysm/recibo_reporte_x.php',
      url: 'http://localhost:8080/sys_mbo/tax_sysm/recibo_reporte_x.php',
      data:JSON.stringify(data),
      type: 'POST',
      success: function(response){
          if(response===1){
          }else{
            console.log(response)
          }
        }
  }); 
    return true;
  }

  export async function postError(data) {
    const response = await fetch(url + "ErrorController/postError", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function delete_red_recuento_detalle(data) {
    const response = await fetch(url + "RecuentoController/delete_red_recuento_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_re_recuento_sts(data) {
    const response = await fetch(url + "RecuentoController/put_re_recuento_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_red_recuento_detalle_row(data) {
    const response = await fetch(url + "RecuentoController/put_red_recuento_detalle_row", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function put_re_recuento_encabezado(data) {
    const response = await fetch(url + "RecuentoController/put_re_recuento_encabezado", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  
export async function procesar_recuento(data) {
  const response = await fetch(url + "RecuentoController/procesar_recuento", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

export async function post_red_recuento_detalle(data) {
  const response = await fetch(url + "RecuentoController/post_red_recuento_detalle", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}


export async function post_re_recuento(data) {
  const response = await fetch(url + "RecuentoController/post_re_recuento", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch(function (e) {
      console.log(e);
    });
  return response;
}

  export async function  get_recuento() {
    var urlFetch = url + "RecuentoController/get_recuento"+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_recuento_detalle(re_identificador) {
    var urlFetch = url + "RecuentoController/get_recuento_detalle/"+re_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_movimiento() {
    var urlFetch = url + "CompraController/get_movimiento/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_movimiento_detallado(art_id) {
    var urlFetch = url + "CompraController/get_movimiento_detallado/"+art_id+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function  get_ord_ordenes_ord({ordd_identificador}) {
    var urlFetch = url + "OrdenController/get_ord_ordenes_ord/"+ordd_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_ord_ordenes_list() {
    var urlFetch = url + "OrdenController/get_ord_ordenes_list/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cot_cotizacion_list() {
    var urlFetch = url + "CotizacionController/get_cot_cotizacion_list/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cot_cotizacion_fac({cot_identificador}) {
    var urlFetch = url + "CotizacionController/get_cot_cotizacion_fac/"+cot_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function put_cot_cotizacion_encabezado(data) {
    const response = await fetch(url + "CotizacionController/put_cot_cotizacion_encabezado", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export async function put_entregado_orden(data) {
    const response = await fetch(url + "OrdenController/put_entregado_orden", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function put_ordd_detalle(data) {
    const response = await fetch(url + "OrdenController/put_ordd_detalle", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function post_ordd_detalle(data) {
    const response = await fetch(url + "OrdenController/post_ordd_detalle", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_crp_credito_pago(data) {
    const response = await fetch(url + "CreditoController/post_crp_credito_pago", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function post_cotd_cotizacion_detail_row(data) {
    const response = await fetch(url + "CotizacionController/post_cotd_cotizacion_detail_row", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function delete_cotd_cotizacion_detail(data) {
    const response = await fetch(url + "CotizacionController/delete_cotd_cotizacion_detail", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }

  export async function  get_report_ventas_mensuales({startDate,endDate}) {
    var urlFetch = url + "ReportsController/get_report_ventas_mensuales/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  export async function  get_report_productos_masvendidos({startDate,endDate}) {
    var urlFetch = url + "ReportsController/get_report_productos_masvendidos/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_report_utilidad_margen({startDate,endDate}) {
    var urlFetch = url + "ReportsController/get_report_utilidad_margen/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cr_credito() {
    var urlFetch = url + "CreditoController/get_cr_credito/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cr_credito_detalle(crd_cliente) {
    var urlFetch = url + "CreditoController/get_cr_credito_detalle/"+crd_cliente+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_cr_credito_detalle_byIdentificador(crd_fac_identificador) {
    var urlFetch = url + "CreditoController/get_cr_credito_detalle_byIdentificador/"+crd_fac_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_report_creditos({startDate,endDate}) {
    var urlFetch = url + "ReportsController/get_report_creditos/"+startDate+"/"+endDate+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }

  export async function  get_crp_credito_pago(crd_fac_identificador) {
    var urlFetch = url + "CreditoController/get_crp_credito_pago/"+crd_fac_identificador+"/"+moment(new Date()).format("YYYYMMDDHHmmss");
    const response = fetch(urlFetch)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
    return response;
  }
  
  export async function put_facturado_orden(data) {
    const response = await fetch(url + "OrdenController/put_facturado_orden", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  export async function delete_crp_credito_pago(data) {
    const response = await fetch(url + "CreditoController/put_crp_credito_pago_sts", {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch(function (e) {
        console.log(e);
      });
    return response;
  }
  
  export  async function print_recibo_pago(data) {
    $.ajax({
      url: 'http://localhost:8080/sys_mbo/tax_sysm/recibo_pago_credito.php',
      data:JSON.stringify(data),
      type: 'POST',
      success: function(response){
          if(response===1){
          }else{
            console.log(response)
          }
        }
  }); 
    return true;
  }

  export  async function print_recibo_pagoCopia(data) {
    $.ajax({
      url: 'http://localhost:8080/sys_mbo/tax_sysm/recibo_pago_creditoCopia.php',
      data:JSON.stringify(data),
      type: 'POST',
      success: function(response){
          if(response===1){
          }else{
            console.log(response)
          }
        }
  }); 
    return true;
  }